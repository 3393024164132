import * as React from 'react';
import { Button, Box, Grid, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { useState } from 'react';
import tw from 'twin.macro';

export const Title = 'Identity';

interface UserProfile {
  firstName: string;
  lastName: string;
  birthday: string;
  country: string;
}

const Identity = () => {
  const [profile, setProfile] = useState<UserProfile>({
    firstName: '',
    lastName: '',
    birthday: '',
    country: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(profile);
  };

  return (
    <Box sx={tw`pl-24 pt-8`}>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} sx={{ maxWidth: 500 }}>
        <Grid container spacing={2}>
          <Grid item xs={4} sx={tw`flex items-center`}>
            <InputLabel htmlFor="firstName">First Name</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                size="small"
                id="firstName"
                name="firstName"
                value={profile.firstName}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={tw`flex items-center`}>
            <InputLabel htmlFor="lastName">Last Name</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                size="small"
                id="lastName"
                name="lastName"
                value={profile.lastName}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={tw`flex items-center`}>
            <InputLabel htmlFor="birthday">Birthday</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput
                size="small"
                id="birthday"
                type="date"
                name="birthday"
                value={profile.birthday}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={tw`flex items-center`}>
            <InputLabel htmlFor="country">Country</InputLabel>
          </Grid>
          <Grid item xs={8}>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInput size="small" id="country" name="country" value={profile.country} onChange={handleChange} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 2 }}>
              <Button color="success" size="large" type="submit" variant="contained" sx={{ width: 200 }}>
                Save
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Identity;
