import tw from 'twin.macro';
import styled from 'styled-components';
import { Bm_Progress_Satus, Maybe } from 'graphql/schema/__generated__/graphql-types';

const Span = styled.span<{ status: Bm_Progress_Satus | string }>(({ status }) => [
  status === Bm_Progress_Satus.Launching && tw`bg-green1-500`,
  status === Bm_Progress_Satus.AlmostDone && tw`bg-orange1-500`,
  status === Bm_Progress_Satus.SoldOut && tw` bg-red1-500`,
  status === Bm_Progress_Satus.ComingSoon && tw` bg-blue-500`,
  status === Bm_Progress_Satus.Open && tw`bg-teal-500`,
  status === Bm_Progress_Satus.LastChance && tw` bg-yellow-500`,
  tw`w-full rounded-md text-center p-1 text-white uppercase block`,
  status === Bm_Progress_Satus.Bizzare && tw` bg-yellow-200 text-red-500`,
  status === Bm_Progress_Satus.NotFunded && tw` bg-secondary-100`,
  status === Bm_Progress_Satus.Cancelled && tw` bg-white text-red-500`,
]);
//tw`absolute w-24 rounded-md text-center mx-2 my-2 px-2 py-1 text-white uppercase top-0 left-0`
export const AdStatus = (props: { status?: Maybe<Bm_Progress_Satus> }) => {
  const status = props.status ? props.status.replaceAll('_', ' ') : "";
  return <Span status={props.status ?? ""}>{status}</Span>;
};

export default AdStatus;
