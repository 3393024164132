export const TermsOfUseData = `

# Introduction

Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use. If you disagree with any part of these terms and conditions, please do not use our website.

# User obligations

By downloading, accessing or using our website, you represent that you are at least eighteen (18) years old or the legal age of majority, whichever is greater, and you are agreeing to these Terms. You also agree to abide by all applicable local, state, and national laws and regulations with respect to your use of the website. You further represent you shall at all times provide true, accurate, current, and complete information (and updates thereto) when submitting information.

# Ownership and Intellectual Property

All content included on the website, such as text, graphics, logos, images, audio clips, video, data, music, software, and other material (collectively "Content") is owned or licensed property of our company and is protected by copyright, trademark, patent, or other proprietary rights.

# Changes to Terms

We reserve the right, in our sole discretion, to change these Terms at any time. Any changes will be effective immediately upon posting on the website. 

# Contact Information

If you have any questions regarding these Terms, please contact us.
`;
