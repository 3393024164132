import { Settings, Investment } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import Briicks from './briicks';
import Limits from './limits';
import Contract from './contract';

export const ARTICLE_TITLE = 'Offering';

const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;
const Divider = tw.hr`
`;

const Offering = (props: {
  investment: Investment;
  settings: Settings;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const { investment } = props;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
        <Headline>{ARTICLE_TITLE}</Headline>
        <Divider />
        <Briicks investment={investment} />
        <Limits investment={investment} />
        <Contract investment={investment} />
      </ArticleContainer>
    </>
  );
};

export default Offering;
