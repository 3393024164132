import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import tw from 'twin.macro';

const AccessView = (props: { setAction: Dispatch<SetStateAction<'change-email' | 'change-password' | null>> }) => {
  const { setAction } = props;

  const setChangeEmail = () => {
    setAction('change-email');
  };

  const setChangePassword = () => {
    setAction('change-password');
  };
  return (
    <>
      <Box sx={tw`pl-24 pt-8`}>
        <Box component="form" noValidate autoComplete="off" sx={{ width: 500 }}>
          <Grid container spacing={2}>
            <Grid item xs={3} sx={tw`flex items-center`}>
              <Typography
                variant="body1"
                sx={{
                  color: 'text.secondary',
                }}
              >
                Email
              </Typography>
            </Grid>
            <Grid item xs={9} sx={tw`flex items-center`}>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} sx={tw`w-full`}>
                <Typography variant="body1" sx={tw`font-semibold text-secondary-500`}>
                  example@domain.com
                </Typography>
                <Button onClick={() => setChangeEmail()}>Change it</Button>
              </Stack>
            </Grid>
            <Grid item xs={12} sx={tw`flex items-center w-full`}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 3,
                  mb: 2,
                }}
              >
                <Button color="success" size="large" variant="contained" onClick={() => setChangePassword()}>
                  Change the password
                </Button>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    marginTop: 1,
                  }}
                >
                  last changed at 01/01/2014
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AccessView;
