import { IFullAdsSection } from 'components/common/types';
import { Financial, Settings } from 'graphql/schema/__generated__/graphql-types';
import { ThemeProvider } from '@mui/material';
import SectionContainer from 'components/article/section-container';
import { theme } from '../../theme';
import PropertyValues, { ARTICLE_TITLE as PropertyValuesName } from './property-values';
import AcquisitionCost, { ARTICLE_TITLE as AcquisitionCostName } from './acquisition-cost';
import OperatingExpenses, { ARTICLE_TITLE as OperatingExpensesName } from './operating-expenses';
import LeaseDetails, { ARTICLE_TITLE as LeaseDetailsName } from './lease-details';
import Liabilities, { ARTICLE_TITLE as LiabilitiesName } from './liabilities';
import { Maybe } from 'graphql/jsutils/Maybe';

export const elements: IFullAdsSection[] = [
  {
    name: PropertyValuesName,
    element: PropertyValues,
  },
  {
    name: AcquisitionCostName,
    element: AcquisitionCost,
  },
  {
    name: OperatingExpensesName,
    element: OperatingExpenses,
  },
  {
    name: LeaseDetailsName,
    element: LeaseDetails,
  },
  {
    name: LiabilitiesName,
    element: Liabilities,
  },
];

const FinancialSection = (props: {
  financial?: Maybe<Financial>;
  settings?: Maybe<Settings>;
  viewPortObserver: (articleName: string, isVisible: boolean) => void;
}) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <SectionContainer>
          {elements.map((e, index) => {
            const V = e.element;
            return (
              <V
                financial={props.financial}
                settings={props.settings}
                id={e.name}
                key={index}
                viewPortObserver={props.viewPortObserver}
              />
            );
          })}
        </SectionContainer>
      </ThemeProvider>
    </>
  );
};

export default FinancialSection;
