import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import { Divider } from '@mui/material';
import _ from 'lodash';
import { Maybe, Property, Settings } from 'graphql/schema/__generated__/graphql-types';

const Container = tw.div`
col-span-2
`;
const Heading2 = tw.h2`
font-medium text-base text-secondary-900 mr-1
`;
const Val = tw.div`
text-sm text-blue-500
`;

const PropertyView = (props: { property?: Maybe<Property>; settings?:  Maybe<Settings>; maker?:  Maybe<string> }) => {
  const { property, settings, maker } = props;

  const type = property?.classification?.type ?? 'no data';
  let bath_bed = '--';

  if (maker?.toLocaleLowerCase() === 'real token') {
    const interior = _.find(property?.features, (o) => o.category.toLocaleLowerCase() === 'interior');
    if (interior) {
      bath_bed = _.find(interior?.values, (o) => o.key.toLocaleLowerCase() === 'bedroom/bath')?.value ?? '--';
    }
  }

  const values: string[] = [type, bath_bed];

  const specifications = property?.specifications;
  let areaHabitable: string | null = null;

  const area = _.find(specifications, (o) => o.category.toLocaleLowerCase() === 'area');
  if (area) {
    areaHabitable = _.find(area.values, (o) => o.key.toLocaleLowerCase() === 'habitable')?.value ?? null;
  }
  return (
    <Container>
      <Heading2>Property</Heading2>
      <Divider variant={undefined} css={tw`bg-secondary-900 mr-2!`} />
      {values.map((e: string, index: number) => (
        <Val key={index} css={tw`capitalize`}>
          {e}
        </Val>
      ))}
      <Val>
        {areaHabitable !== null ? (
          <NumberFormat
            value={areaHabitable}
            decimalScale={0}
            fixedDecimalScale
            displayType={'text'}
            thousandSeparator={' '}
            suffix={` ${settings?.measurementMetrics}`}
            renderText={(value: string) => <span>{value}</span>}
          />
        ) : (
          '--'
        )}
      </Val>
    </Container>
  );
};

export default PropertyView;
