import { Settings, Property } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { Box, Link, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import VrpanoIcon from '@mui/icons-material/Vrpano';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import CollectionsIcon from '@mui/icons-material/Collections';
import VillaIcon from '@mui/icons-material/Villa';
import _ from 'lodash';
import { TabPanel } from 'components/tabs/tab-panel';
import PhotoGallery from './photo-gallery';

export const ARTICLE_TITLE = 'Gallery';

const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;
const Divider = tw.hr`
`;

const MediaGallery = (props: {
  property: Property;
  settings: Settings;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { mediaGallery } = props.property;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
        <Headline>{ARTICLE_TITLE}</Headline>
        <Divider />

        <Tabs value={value} onChange={handleChange} centered css={tw`mt-4`}>
          <Tab sx={tw`text-blue-500`} icon={<CollectionsIcon />} label="Pictures" />
          <Tab sx={tw`text-blue-500`} icon={<VillaIcon />} label="Plan" disabled />
          <Tab sx={tw`text-blue-500`} icon={<VrpanoIcon />} label="360" disabled={_.isEmpty(mediaGallery?.view360)} />
          <Tab sx={tw`text-blue-500`} icon={<CameraswitchIcon />} label="Virtual" disabled />
          <Tab sx={tw`text-blue-500`} icon={<ViewInArIcon />} label="3D" disabled />
        </Tabs>
        <TabPanel index={0} value={value}>
          <PhotoGallery photosGalleries={mediaGallery?.photoGallery} />
        </TabPanel>
        {!_.isEmpty(mediaGallery?.view360) && (
          <TabPanel index={2} value={value}>
            <Box>
              <Link href={mediaGallery?.view360 ?? '#'} target="_blank">
                {mediaGallery?.view360}
              </Link>
            </Box>
          </TabPanel>
        )}
      </ArticleContainer>
    </>
  );
};

export default MediaGallery;
