import tw from 'twin.macro';
import { SourcesIcon } from 'components/misc/SourcesIcon';
import { Box, Divider } from '@mui/material';
import { Maybe } from 'graphql/schema/__generated__/graphql-types';

const Container = tw.div`
col-span-3 pr-1
`;
const Heading2 = tw.h2`
font-medium text-base text-secondary-900 mr-1
`;

const BriicksMakerLogo = tw.img`
w-auto h-8 max-w-24 self-center
`;
const Maker = (props: { maker?:  Maybe<string> }) => {
  return (
    <Container>
      <Heading2>Proposed by</Heading2>
      <Divider variant={undefined} css={tw`bg-secondary-900 mr-2!`} />
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: 1 }}>
        <BriicksMakerLogo src={SourcesIcon(props.maker ?? 'no data')} />
      </Box>
    </Container>
  );
};

export default Maker;
