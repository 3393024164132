import { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SliderBase as SliderStyle } from "components/misc/React-Slick";
import Slider from "react-slick";
import { LazyBackground } from "components/misc/LazyBackground";
import { PhotoGallery } from "graphql/schema/__generated__/graphql-types";
import _ from "lodash";
import noImage from "images/no_picture.png";

const ImageSlide = styled.div<{ imgSrc: string }>(({ imgSrc }) => [
  `background-image: url("${imgSrc}");`,
  tw`min-w-full h-64 lg:h-72 bg-cover bg-center rounded-tl-lg rounded-tr-lg border-b`,
]);
//lg:h-96 h-64
const ImageSlider = (props: {
  photoGallery: PhotoGallery[];
  onSliderRef: (ref: Slider) => void;
}) => {
  //@ts-ignore
  const [, setSliderRef] = useState<Slider | null>(null);
  const { photoGallery } = props;
  let imagesSrc: string[] = _.chain(photoGallery)
    .map((e) => e.pictures)
    .flatMap()
    .value();

  if (_.isEmpty(imagesSrc)) imagesSrc = [noImage];
  //console.log(imagesSrc);
  return (
    <SliderStyle
      arrows={false}
      ref={(ref: Slider) => {
        setSliderRef(ref);
        if (ref) props.onSliderRef(ref);
      }}
    >
      {imagesSrc.map((src, index) => {
        return (
          src && (
            <LazyBackground key={index} src={src}>
              <ImageSlide key={index} imgSrc={src} />
            </LazyBackground>
          )
        );
      })}
    </SliderStyle>
  );
};

export default ImageSlider;
