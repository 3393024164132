import { Settings, Investment, Maybe } from 'graphql/schema/__generated__/graphql-types';
import { IFullAdsSection } from '..';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../theme';
import SectionContainer from 'components/article/section-container';
import InvestmentStrategy, { ARTICLE_TITLE as InvestmentStrategyName } from './strategy';
import Offering, { ARTICLE_TITLE as OfferingName } from './offering';
import Metrics, { ARTICLE_TITLE as MetricsName } from './metrics';
import Calendar, { ARTICLE_TITLE as CalendarName } from './calendar';

export const elements: IFullAdsSection[] = [
  {
    name: InvestmentStrategyName,
    element: InvestmentStrategy,
  },
  {
    name: OfferingName,
    element: Offering,
  },
  {
    name: MetricsName,
    element: Metrics,
  },
  {
    name: CalendarName,
    element: Calendar,
  },
];
const InvestmentSection = (props: {
  investment?: Maybe<Investment>;
  settings?: Maybe<Settings>;
  viewPortObserver: (articleName: string, isVisible: boolean) => void;
}) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <SectionContainer>
          {elements.map((e, index) => {
            const V = e.element;
            return (
              <V
                investment={props.investment}
                settings={props.settings}
                id={e.name}
                key={index}
                viewPortObserver={props.viewPortObserver}
              />
            );
          })}
        </SectionContainer>
      </ThemeProvider>
    </>
  );
};

export default InvestmentSection;
