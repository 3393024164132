import Footer from 'components/footer';
import { HomeContent } from './content';
import MainSection from './main-section';
import SubscribeToSocialsSection from './subscribe-to-socials-section';
//import AnimationRevealPage from 'helpers/AnimationRevealPage.js';

import HighlightedListingSection from './highlighted-listing-section';
import StatsSection from './stats-section';
import SubscribeNewsLetter from 'components/form/subscribe-news-letter';

const HomePage = () => {
  return (
    <>
      <MainSection content={HomeContent.MainSection} />
      <SubscribeToSocialsSection content={HomeContent.SubscribeToSocialsSection} />
      <HighlightedListingSection content={HomeContent.HighlightedListingSection} />
      <StatsSection content={HomeContent.StatsSection} />
      <SubscribeNewsLetter />
      <Footer />
    </>
  );
};

export default HomePage;
