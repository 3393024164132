import { Box, Button as B } from '@mui/material';
import styled from 'styled-components';
import tw from 'twin.macro';

const Button = styled(B)`
  ${tw`bg-red-500`}
`;
const MakerDashboard = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Button>Salut</Button>
    </Box>
  );
};

export default MakerDashboard;
