import { Box, Typography } from '@mui/material';
import { Investment } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';

const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const Recomendation = (props: { investment: Investment }) => {
  const paragraphs: string[] = [];

  if (props.investment?.strategy?.trim().toLowerCase() === 'long-term') {
    paragraphs.push(
      `Residential rental-type investments may be particularly appealing to individuals seeking a steady, long-term income stream.`,
    );
    paragraphs.push(
      `Since each property is professionally managed, you won't have to worry about the day-to-day responsibilities of being a landlord.`,
    );
    paragraphs.push(
      `An investor in residential rentals should be comfortable with the potential of vacancies or maintenance issues, and must be patient, as significant returns from rental properties typically occur over a longer time frame`,
    );
  } else {
    paragraphs.push('no data');
  }
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>
          Is this investment right for <Title css={tw`inline text-green-500 uppercase`}>Me</Title>?
        </Title>
        {paragraphs.map((e, index: number) => (
          <Typography key={index} variant="body1" gutterBottom sx={{ marginTop: 1 }}>
            {e}
          </Typography>
        ))}
      </Box>
    </>
  );
};

export default Recomendation;
