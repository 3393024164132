import Header from 'components/header';
import Footer from 'components/footer';
//import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { ContentWithPaddingXl } from 'components/misc/Layouts';
import { SectionHeading } from 'components/misc/Headings.js';
import tw from 'twin.macro';
import { useState } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as ChevronDownIcon } from 'feather-icons/dist/icons/chevron-down.svg';
import ContactUsForm from 'components/form/contact-us';
import SubscribeNewsLetter from 'components/form/subscribe-news-letter';

const Heading = tw(SectionHeading)`w-full`;
const Subheading = tw.div`mb-8 text-center text-xl text-gray-700`;
const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;
const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

interface IFaq {
  question: string;
  answer: string;
}
export interface IFaqContent {
  Title: string;
  CTAText: {
    Title: string;
    Description: string;
  };
  faqs: IFaq[];
}
const FaqPage = (props: { content: IFaqContent }) => {
  return (
    <>
      <>
        <Header />
        <ContentWithPaddingXl>
          <Column>
            <HeaderContent>
              <Heading>{props.content.Title}</Heading>
            </HeaderContent>
            <FAQSContainer>{FaqQuestionAnswer({ faqs: props.content.faqs })}</FAQSContainer>
          </Column>
        </ContentWithPaddingXl>
        <ContentWithPaddingXl tw="py-0">
          <Heading>{props.content.CTAText.Title}</Heading>
          <Subheading>{props.content.CTAText.Description}</Subheading>
        </ContentWithPaddingXl>
        <ContactUsForm />
        <SubscribeNewsLetter />
        <Footer />
      </>
    </>
  );
};

export default FaqPage;

const FaqQuestionAnswer = (props: { faqs: IFaq[] }) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number | null>(null);

  const toggleQuestion = (questionIndex: number) => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return props.faqs.map((faq, index) => (
    <FAQ
      key={index}
      onClick={() => {
        toggleQuestion(index);
      }}
      className="group"
    >
      <Question>
        <QuestionText>{faq.question}</QuestionText>
        <QuestionToggleIcon
          variants={{
            collapsed: { rotate: 0 },
            open: { rotate: -180 },
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? 'open' : 'collapsed'}
          transition={{
            duration: 0.02,
            ease: [0.04, 0.62, 0.23, 0.98],
          }}
        >
          <ChevronDownIcon />
        </QuestionToggleIcon>
      </Question>
      <Answer
        variants={{
          open: { opacity: 1, height: 'auto', marginTop: '16px' },
          collapsed: { opacity: 0, height: 0, marginTop: '0px' },
        }}
        initial="collapsed"
        animate={activeQuestionIndex === index ? 'open' : 'collapsed'}
        transition={{
          duration: 0.3,
          ease: [0.04, 0.62, 0.23, 0.98],
        }}
      >
        {faq.answer}
      </Answer>
    </FAQ>
  ));
};
