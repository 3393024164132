import { Settings, Property } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import PropertyAddress from './address';
import AboutCity from './about-city';
import MapsView from './maps';

export const ARTICLE_TITLE = 'Location';

const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;

const Divider = tw.hr`
`;

const PropertyLocation = (props: {
  property: Property;
  settings: Settings;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const { property } = props;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
        <Headline>{ARTICLE_TITLE}</Headline>
        <Divider />
        <PropertyAddress property={property} />
        <AboutCity property={property} />
        <MapsView property={property} />
      </ArticleContainer>
    </>
  );
};

export default PropertyLocation;
