import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Maybe, Property } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const PropertyFeatures = (props: { property: Maybe<Property> }) => {
  const features = props.property?.features;
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>Features</Title>
        <Box flexDirection="column" sx={{ display: 'flex', justifyContent: 'center' }}>
          {features?.map((feature, index: number) => (
            <Box key={index} sx={{ width: 850, alignSelf: 'center', marginTop: 2 }}>
              <Typography variant="h6" component="div" sx={tw`text-green-500`}>
                {feature.category}
              </Typography>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    minWidth: 650,
                    '& .MuiTableCell-root': {
                      padding: 1,
                      //border: 1
                    },
                  }}
                  aria-label="simple table"
                >
                  <TableBody>
                    {feature.values.map((e, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {e.key}
                        </TableCell>
                        <TableCell align="right" sx={tw`capitalize`}>
                          {e.key.trim().toLowerCase() === 'no data' ? '' : e.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default PropertyFeatures;
