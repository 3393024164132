import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import Identity, { Title as IdentityTitle } from './sections/identity';
import Access, { Title as AccessTitle } from './sections/access';
import Settings, { Title as SettingsTitle } from './sections/settings';
import MyList, { Title as MyListTitle } from './sections/my-list';
import { TabPanel } from 'components/tabs/tab-panel';
import tw from 'twin.macro';

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const TabsElements: {
  title: string;
  element: () => JSX.Element | null;
}[] = [
  {
    title: IdentityTitle,
    element: Identity,
  },
  {
    title: AccessTitle,
    element: Access,
  },
  {
    title: SettingsTitle,
    element: Settings,
  },
  {
    title: MyListTitle,
    element: MyList,
  },
];

const TakerAccount = () => {
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2, bgcolor: 'background.paper', display: 'flex' }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs"
          sx={{ borderRight: 1, borderColor: 'divider' }}
          TabIndicatorProps={{ sx: tw`bg-primary-500` }}
        >
          {TabsElements.map((e, index: number) => (
            <Tab
              key={index}
              label={
                <Typography
                  sx={{
                    ...tw`capitalize text-lg font-semibold`,
                    ...(value === index ? tw`text-primary-500` : tw``),
                  }}
                >
                  {e.title}
                </Typography>
              }
              {...a11yProps(0)}
            />
          ))}
        </Tabs>
        {TabsElements.map((e, index: number) => (
          <TabPanel key={index} value={value} index={index}>
            <e.element />
          </TabPanel>
        ))}
      </Box>
    </>
  );
};

export default TakerAccount;
