import { Box, Typography } from '@mui/material';
import tw from 'twin.macro';

const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const Disclaimer = () => {
  const paragraphs: string[] = [];
  paragraphs.push(`The financial metrics presented in this offer are calculated based on
  estimations provided by the Briickmaker. These estimates are subject to market
  and economic risks and should not be interpreted as a guarantee of future
  performance. Actual results may be higher or lower than these estimates.
  Prospective investors are advised to conduct their own due diligence and
  consider their risk tolerance before making an investment decision`);
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>Disclaimer</Title>
        <Box css={tw`bg-orange1-100 p-2 `}>
          {paragraphs.map((e, index: number) => (
            <Typography key={index} variant="body1" gutterBottom sx={{ marginTop: 1, fontStyle: 'italic' }}>
              {e}
            </Typography>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Disclaimer;
