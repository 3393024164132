import {
  Box,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Investment } from "graphql/schema/__generated__/graphql-types";
import { ComponentType } from "react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { SvgIconProps } from "@mui/material/SvgIcon";
import PauseCircleOutlineRoundedIcon from "@mui/icons-material/PauseCircleOutlineRounded";

import tw from "twin.macro";
import NumberFormat from "react-number-format";
import { Theme } from "@emotion/react";
import _ from "lodash";
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const TotalInvestment = (props: { investment: Investment }) => {
  const investment = props?.investment || undefined;
  const Data: {
    icon: ComponentType<SvgIconProps>;
    iconSx: SxProps<Theme>;
    textSx: SxProps<Theme>;
    title: string;
    value: number | null;
  }[] = [
    {
      icon: AddCircleOutlineRoundedIcon,
      title: "Total Acquisition Cost",
      value: investment?.metrics?.totalInvestment?.totalAcquisitionCost || null,
      iconSx: { marginRight: 1 },
      textSx: tw`text-secondary-500`,
    },
    {
      icon: AddCircleOutlineRoundedIcon,
      title: "Total Assets Price",
      value: investment?.metrics?.totalInvestment?.totalAssetPrice || null,
      iconSx: { marginRight: 1 },
      textSx: tw`text-secondary-500`,
    },
    {
      icon: PauseCircleOutlineRoundedIcon,
      title: "Total Investment",
      value: investment?.metrics?.totalInvestment?.total || null,
      iconSx: {
        marginRight: 1,
        transform: "rotate(90deg)",
        ...tw`text-2xl text-green-500`,
      },
      textSx: tw`text-secondary-500 font-bold`,
    },
  ];
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>Total Investment</Title>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: 850, alignSelf: "center", marginTop: 2 }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  "& .MuiTableCell-root": {
                    padding: 1,
                    //border: 1
                  },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {Data.map((e, index: number) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant={
                            index === Data.length - 1 ? "body1" : "body2"
                          }
                          sx={e.textSx}
                        >
                          <e.icon sx={e.iconSx} />
                          {e.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" sx={e.textSx}>
                        <Typography
                          variant={
                            index === Data.length - 1 ? "body1" : "body2"
                          }
                          sx={e.textSx}
                        >
                          {!_.isNumber(e.value) ? (
                            "no data"
                          ) : (
                            <NumberFormat
                              value={e.value}
                              decimalScale={2}
                              fixedDecimalScale
                              displayType={"text"}
                              thousandSeparator={" "}
                              prefix={"$ "}
                              renderText={(value: string) => (
                                <span>{value}</span>
                              )}
                            />
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TotalInvestment;
