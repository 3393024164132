import { Box } from '@mui/material';
import { LegalSpv } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const ExternalLinks = (_props: { spv: LegalSpv | undefined }) => {
  //const { spv } = props;
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>External Link</Title>
        no data
      </Box>
    </>
  );
};

export default ExternalLinks;
