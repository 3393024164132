import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Maybe } from "graphql/jsutils/Maybe";
import { Property } from "graphql/schema/__generated__/graphql-types";
import _ from "lodash";
import tw from "twin.macro";
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2 cursor-pointer
`;

const PropertyAddress = (props: { property: Maybe<Property> }) => {
  const location = props.property?.location;

  let state: string | null = null;
  if (location?.stateCode) state = `(${location?.stateCode}) `;
  if (location?.state) state = `${state}${location?.state}`;

  const addressParts: { label: string; value: string }[] = [
    {
      label: "Number",
      value: location?.number ?? "no data",
    },
    {
      label: "Street Name",
      value: location?.street ?? "no data",
    },
    {
      label: "Neighborhood",
      value: location?.neighborhood ?? "no data",
    },
    {
      label: "City",
      value: location?.city ?? "no data",
    },
    {
      label: "Zip Code",
      value: location?.zipCode ?? "no data",
    },
    {
      label: "State",
      value: state ?? "no data",
    },
    {
      label: "Country",
      value: location?.country ?? "no data",
    },
  ];

  let addressLine1 = ``;
  if (_.isString(location?.number) && !_.isEmpty(location?.number))
    addressLine1 = `${location?.number || ""}`;
  if (_.isString(location?.street) && !_.isEmpty(location?.street))
    addressLine1 = `${addressLine1} ${location?.street || ''}`;

  let addressLine2 = ``;
  if (_.isString(location?.city) && !_.isEmpty(location?.city))
    addressLine2 = `${location?.city || ''},`;
  if (_.isString(location?.state) && !_.isEmpty(location?.state))
    addressLine2 = `${addressLine2} ${location?.state || ''}`;
  if (_.isString(location?.zipCode) && !_.isEmpty(location?.zipCode))
    addressLine2 = `${addressLine2} ${location?.zipCode || ''}`;

  const handleClick = () => {
    navigator.clipboard.writeText(`${addressLine1} ${addressLine2} ${location?.country ?? ""}`.trim());
  };
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Tooltip title="Copy" placement="top-start">
          <Title onClick={handleClick}>Address</Title>
        </Tooltip>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: 850, alignSelf: "center", marginTop: 2 }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  "& .MuiTableCell-root": {
                    padding: 1,
                    //border: 1
                  },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {addressParts.map((e, index: number) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {e.label}
                      </TableCell>
                      <TableCell align="right" sx={tw`capitalize`}>
                        {e.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PropertyAddress;
