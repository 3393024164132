import { IFullAdsSection } from 'components/common/types';
import { Property, Settings } from 'graphql/schema/__generated__/graphql-types';
import { ThemeProvider } from '@mui/material';
import SectionContainer from 'components/article/section-container';
import { theme } from '../../theme';
import PropertyOverview, { ARTICLE_TITLE as OverviewName } from './overview';
import PropertyLocation, { ARTICLE_TITLE as LocationName } from './location';
import MediaGallery, { ARTICLE_TITLE as MediaGalleryName } from './media-gallery';
import PropertyDetails, { ARTICLE_TITLE as PropertyDetailsName } from './details';
import PropertySpecifications, { ARTICLE_TITLE as PropertySpecificationsName } from './specifications';
import { Maybe } from 'graphql/jsutils/Maybe';

export const elements: IFullAdsSection[] = [
  {
    name: OverviewName,
    element: PropertyOverview,
  },
  {
    name: LocationName,
    element: PropertyLocation,
  },
  {
    name: MediaGalleryName,
    element: MediaGallery,
  },
  {
    name: PropertyDetailsName,
    element: PropertyDetails,
  },
  {
    name: PropertySpecificationsName,
    element: PropertySpecifications,
  },
];

const PropertySection = (props: {
  property?: Maybe<Property>;
  settings?: Maybe<Settings>;
  strategy?: Maybe<string>;
  viewPortObserver: (articleName: string, isVisible: boolean) => void;
}) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <SectionContainer>
          {elements.map((e, index) => {
            const V = e.element;
            return (
              <V
                property={props.property}
                strategy={props.strategy}
                settings={props.settings}
                id={e.name}
                key={index}
                viewPortObserver={props.viewPortObserver}
              />
            );
          })}
        </SectionContainer>
      </ThemeProvider>
    </>
  );
};

export default PropertySection;
