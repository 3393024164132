import Header from 'components/header';
import Footer from 'components/footer';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import tw from 'twin.macro';
import ReactMarkdown from 'react-markdown';
import { TermsOfUseData } from './data';
import styled from 'styled-components';

const Container = tw(ContainerBase)`-mx-8 px-4 `;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;

const StyledReactMarkdown = styled(ReactMarkdown)`
  ${tw`mt-4`}
  h1 {
    ${tw`font-semibold  text-xl`}
  }
  h2 {
    ${tw`font-semibold text-lg`}
  }
`;
const Divider = tw.hr`
`;

const TermsOfUse = () => {
  return (
    <>
      <Header />
      <Container>
        <Content>
          <Headline>Terms Of Use</Headline>
          <Divider />
          <StyledReactMarkdown>{TermsOfUseData}</StyledReactMarkdown>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default TermsOfUse;
