import { Box, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import tw from 'twin.macro';

const ConfirmOPT = () => {
  const [otp, setOtp] = useState('');

  return (
    <>
      <Box sx={tw`mt-6`}>
        <Typography variant="body1" color="primary.main">
          OTP Code was sent, Check your new mailbox !
        </Typography>
        <Stack spacing={4} direction="row" justifyContent="flex-start" alignItems="center">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            inputStyle={tw`w-10`}
            renderSeparator={<span> - </span>}
            renderInput={(props) => <input {...props} css={tw`h-10 border m-2 `} />}
          />
          <Box>
            <Typography variant="caption">OPT Code not recieved?</Typography>
            <Button variant="outlined" size="small" sx={tw`ml-2 text-xs lowercase`}>
              {' '}
              resent it
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default ConfirmOPT;
