import styled from 'styled-components';
import tw from 'twin.macro';

export const PrimaryButton = styled.button`
  ${tw`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`}
  ${(props) => props.disabled && tw`bg-gray-500 hocus:bg-gray-500`}
`;
export const SecondaryButton = styled.button`
  ${tw`px-8 py-3 font-bold rounded bg-secondary-500 text-gray-100 hocus:bg-secondary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`}
  ${(props) => props.disabled && tw`bg-gray-500 hocus:bg-gray-500`}
`;
export const InfoButton = tw.button`px-8 py-3 font-bold rounded bg-gray-200 text-gray-500 focus:shadow-outline focus:outline-none transition duration-300`;
