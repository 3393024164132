import { Box, CardMedia, Grid, Paper, Stack, Typography } from '@mui/material';
import VideoPreview from 'components/media/video-preview';
import { Property } from 'graphql/schema/__generated__/graphql-types';
import _ from 'lodash';
import tw from 'twin.macro';
import ReactCountryFlag from 'react-country-flag';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;
const Showcase = (props: { property: Property; strategy: string }) => {
  const { property } = props;
  const picture =
    _.chain(property?.mediaGallery?.photoGallery)
      .map((e) => e.pictures)
      .flatMap()
      .value()
      .shift() ?? '';
  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 2, marginTop: 2 }}>
        <Grid container spacing={2}>
          <Grid xs={9}>
            <Title>Showcase</Title>
            {
              //<Title>Presentation</Title>
              property.video ? (
                <VideoPreview source={property.video} />
              ) : (
                <CardMedia
                  component="img"
                  image={picture}
                  //alt="green iguana"
                />
              )
            }
          </Grid>
          <Grid xs={3}>
            <Stack
              spacing={4}
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={tw`h-full`}
              //sx={tw` flex items-center justify-center`}
            >
              <Paper
                elevation={0}
                sx={{ width: 180, padding: 1, ...tw`flex items-center flex-col border border-gray-300` }}
              >
                <Grid container minHeight={60}>
                  {/* Left Column */}
                  <Grid item xs={3} sx={{ height: '100%' }}>
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                      {
                        property?.location?.countryCode &&
                        <ReactCountryFlag
                        countryCode={property?.location?.countryCode}
                        svg
                        style={{
                          width: 40,
                          height: 'auto',
                        }}
                      />}
                    </Box>
                  </Grid>

                  {/* Right Column */}
                  <Grid item xs={9} sx={{ height: '100%' }}>
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                      <Typography variant="body1" sx={tw`capitalize text-center`}>
                        {property?.location?.country}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                elevation={0}
                sx={{ width: 180, padding: 1, ...tw`flex  items-center flex-col border border-gray-300` }}
              >
                <Grid container minHeight={60}>
                  {/* Left Column */}
                  <Grid item xs={3} sx={{ height: '100%' }}>
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                      <HolidayVillageIcon sx={tw`w-8 h-8`} />
                    </Box>
                  </Grid>

                  {/* Right Column */}
                  <Grid item xs={9} sx={{ height: '100%' }}>
                    <Stack height="100%" direction="column" justifyContent="center" alignItems="center" spacing={1}>
                      <Typography variant="body2" sx={tw`uppercase`}>
                        {property?.classification?.use?.trim().toLowerCase() === 'no data'
                          ? 'RESIDENTIAL'
                          : property?.classification?.use?.trim().toLowerCase()}
                      </Typography>

                      <Typography variant="body2" sx={tw`uppercase`}>
                        {property?.classification?.type}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                elevation={0}
                sx={{ width: 180, padding: 1, ...tw`flex  items-center flex-col border border-gray-300` }}
              >
                <Grid container minHeight={60}>
                  {/* Left Column */}
                  <Grid item xs={3} sx={{ height: '100%' }}>
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                      <AccountBalanceIcon sx={tw`w-8 h-8`} />
                    </Box>
                  </Grid>

                  {/* Right Column */}
                  <Grid item xs={9} sx={{ height: '100%' }}>
                    <Stack height="100%" direction="column" justifyContent="center" alignItems="center" spacing={1}>
                      <Typography variant="body2" sx={tw`uppercase`}>
                        rental
                      </Typography>

                      <Typography variant="body2" sx={tw`uppercase`}>
                        {props.strategy}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Showcase;
