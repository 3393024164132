import { Legal } from "graphql/schema/__generated__/graphql-types";
import tw from "twin.macro";
import ArticleContainer from "components/article/article-container";
import OtherRestrictions from "./other-restrictions";
import RestrictionItem from "./restriction-item";

export const ARTICLE_TITLE = "Restrictions";

const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;

const Divider = tw.hr`
`;

const Restriction = (props: {
  legal: Legal;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const { legal } = props;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
        <Headline>{ARTICLE_TITLE}</Headline>
        <Divider />
        {legal?.restrictions?.items?.map((e, index: number) => (
          <RestrictionItem key={index} title={e.key} text={e.value} />
        ))}

        {legal?.restrictions?.description ? (
          <OtherRestrictions
            text={legal.restrictions.description}
          />
        ) : (
          "no data"
        )}
      </ArticleContainer>
    </>
  );
};

export default Restriction;
