import { Box, Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Investment } from 'graphql/schema/__generated__/graphql-types';
import { ComponentType } from 'react';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { SvgIconProps } from '@mui/material/SvgIcon';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';

import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import { Theme } from '@emotion/react';
import _ from 'lodash';
const Title = tw.h2`
text-red-500 font-medium text-2xl mt-2
`;

const ReturnOnInvest = (props: { investment: Investment }) => {
  const investment = props?.investment || undefined;
  let returnOnInvest = null;
  if (_.isNumber(investment?.metrics.returnOnInvest.returnOnInvest))
    returnOnInvest = investment.metrics.returnOnInvest.returnOnInvest;
  const Data: {
    icon: ComponentType<SvgIconProps>;
    iconSx: SxProps<Theme>;
    textSx: SxProps<Theme>;
    title: string;
    value: number | null;
    suffix?: string;
    prefix?: string;
  }[] = [
    {
      icon: AddCircleOutlineRoundedIcon,
      title: 'Cash Flow',
      value: investment?.metrics?.returnOnInvest?.cashFlow || null,
      iconSx: { marginRight: 1 },
      textSx: tw`text-secondary-500  capitalize`,
      prefix: '$ ',
    },
    {
      icon: AddCircleOutlineRoundedIcon,
      title: 'Value Appreciation',
      value: investment?.metrics?.returnOnInvest?.valueAppreciation || null,
      iconSx: { marginRight: 1 },
      textSx: tw`text-secondary-500 capitalize`,
      prefix: '$ ',
    },
    {
      icon: RemoveCircleOutlineRoundedIcon,
      title: 'Total Investment',
      value: investment?.metrics?.returnOnInvest?.totalInvestment || null,
      iconSx: { marginRight: 1, transform: 'rotate(-45deg)', ...tw`text-orange-500` },
      textSx: tw`text-secondary-500  capitalize`,
      prefix: '$ ',
    },
    {
      icon: PauseCircleOutlineRoundedIcon,
      title: 'Retun On Invest ( R.O.I. )',
      value: returnOnInvest,
      iconSx: { marginRight: 1, transform: 'rotate(90deg)', ...tw`text-2xl text-green-500` },
      textSx: tw`text-red-500 font-bold  capitalize`,
      suffix: ' %',
    },
  ];
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>Return On Invest ( R.O.I. )</Title>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: 850, alignSelf: 'center', marginTop: 2 }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  '& .MuiTableCell-root': {
                    padding: 1,
                    //border: 1
                  },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {Data.map((e, index: number) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        <Typography variant={index === Data.length - 1 ? 'body1' : 'body2'} sx={e.textSx}>
                          <e.icon sx={e.iconSx} />
                          {e.title}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" sx={e.textSx}>
                        <Typography variant={index === Data.length - 1 ? 'body1' : 'body2'} sx={e.textSx}>
                          {e.value === null ? (
                            'no data'
                          ) : (
                            <NumberFormat
                              value={e.value}
                              decimalScale={2}
                              fixedDecimalScale
                              displayType={'text'}
                              thousandSeparator={' '}
                              prefix={e.prefix}
                              suffix={e.suffix}
                              renderText={(value: string) => <span>{value}</span>}
                            />
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReturnOnInvest;
