import tw from "twin.macro";
import moment from "moment";
import styled from "styled-components";
import {
  Bm_Progress_Satus,
  Maybe,
  SaleProgress,
} from "graphql/schema/__generated__/graphql-types";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";

const Span = tw.span`
    overflow-hidden text-center
    text-white uppercase whitespace-nowrap truncate
    mr-1
    `;
const Div = styled.div<{ status: Bm_Progress_Satus }>(({ status }) => [
  tw`
        w-full flex flex-row mt-1 p-1 rounded-md bg-white  
        items-center justify-center
        `,
  status === Bm_Progress_Satus.ComingSoon && tw` bg-blue-500 `,
  status === Bm_Progress_Satus.Launching && tw`bg-green-500 `,
  status === Bm_Progress_Satus.Open && tw`bg-green-500 `,
  status === Bm_Progress_Satus.AlmostDone && tw`bg-green-500 `,
  status === Bm_Progress_Satus.LastChance && tw`bg-green-500 `,
  status === Bm_Progress_Satus.SoldOut && tw` bg-gray-500`,
]);
export function formatDateDifference__(date1: Date, date2: Date): string {
  // Ensure date1 is the earlier date
  if (date1 > date2) {
    [date1, date2] = [date2, date1];
  }

  // Calculate the total difference in milliseconds
  const diffInMs = date2.getTime() - date1.getTime();

  // Define the lengths of time units in milliseconds
  const msPerSecond = 1000;
  const msPerMinute = msPerSecond * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerWeek = msPerDay * 7;
  const msPerMonth = msPerDay * 30; // Approximate
  const msPerYear = msPerDay * 365; // Approximate

  // Calculate the difference in each time unit
  let years = Math.floor(diffInMs / msPerYear);
  let remainingMsAfterYears = diffInMs % msPerYear;
  let months = Math.floor(remainingMsAfterYears / msPerMonth);
  let remainingMsAfterMonths = remainingMsAfterYears % msPerMonth;
  let weeks = Math.floor(remainingMsAfterMonths / msPerWeek);
  let remainingMsAfterWeeks = remainingMsAfterMonths % msPerWeek;
  let days = Math.floor(remainingMsAfterWeeks / msPerDay);
  let remainingMsAfterDays = remainingMsAfterWeeks % msPerDay;
  let hours = Math.floor(remainingMsAfterDays / msPerHour);
  let remainingMsAfterHours = remainingMsAfterDays % msPerHour;
  let minutes = Math.floor(remainingMsAfterHours / msPerMinute);
  let seconds = Math.floor((remainingMsAfterHours % msPerMinute) / msPerSecond);

  // Format the result based on the largest non-zero unit
  if (years > 0) {
    if (months === 0 && remainingMsAfterYears > 0) {
      months = 1; // Round up if there is any remaining time
    }
    return `${years} years ${months} months`;
  } else if (months > 0) {
    if (weeks === 0 && remainingMsAfterMonths > 0) {
      weeks = 1; // Round up if there is any remaining time
    }
    return `${months} months ${weeks} weeks`;
  } else if (weeks > 0) {
    if (days === 0 && remainingMsAfterWeeks > 0) {
      days = 1; // Round up if there is any remaining time
    }
    return `${weeks} weeks ${days} days`;
  } else if (days > 0) {
    if (hours === 0 && remainingMsAfterDays > 0) {
      hours = 1; // Round up if there is any remaining time
    }
    return `${days} days ${hours} hours`;
  } else if (hours > 0) {
    if (minutes === 0 && remainingMsAfterHours > 0) {
      minutes = 1; // Round up if there is any remaining time
    }
    return `${hours} hours ${minutes} minutes`;
  } else if (minutes > 0) {
    if (seconds === 0 && remainingMsAfterHours > 0) {
      seconds = 1; // Round up if there is any remaining time
    }
    return `${minutes} minutes ${seconds} seconds`;
  } else {
    return `${seconds} seconds`;
  }
}

function formatDateDifference(date1: Date, date2: Date): string {
  let mDate1 = moment(date1);
  let mDate2 = moment(date2);

  // Ensure mDate1 is the earlier date
  if (mDate1.isAfter(mDate2)) {
    [mDate1, mDate2] = [mDate2, mDate1];
  }

  let years = mDate2.diff(mDate1, "years");
  mDate1.add(years, "years");

  let months = mDate2.diff(mDate1, "months");
  mDate1.add(months, "months");

  let days = mDate2.diff(mDate1, "days");
  mDate1.add(days, "days");

  let hours = mDate2.diff(mDate1, "hours");
  mDate1.add(hours, "hours");

  let minutes = mDate2.diff(mDate1, "minutes");
  mDate1.add(minutes, "minutes");

  let seconds = mDate2.diff(mDate1, "seconds");

  // Construct the result string
  let result = [];

  if (years > 0) {
    result.push(`${years} years`);
  }
  if (months > 0) {
    result.push(`${months} months`);
  }
  if (days > 0) {
    result.push(`${days} days`);
  }
  if (hours > 0) {
    result.push(`${hours} hours`);
  }
  if (minutes > 0) {
    result.push(`${minutes} minutes`);
  }
  if (seconds > 0) {
    result.push(`${seconds} seconds`);
  }

  // Return only the first two elements if there are more than two
  return result.length > 2 ? result.slice(0, 2).join(" ") : result.join(" ");
}

export const DaysCount = (props: {
  progress?: Maybe<SaleProgress>;
  firstLaunchSaleDate?: Date;
  latestLaunchSaleDate?: Date;
  finalSaleEndDate?: Date;
}) => {
  // const date_1 = new Date("2023-01-29 20:23");
  // const date_2 = new Date("2023-01-23 20:12");

  //const firstLaunchSaleDate = moment.utc(props.firstLaunchSaleDate)
  const latestLaunchSaleDate = moment.utc(props.latestLaunchSaleDate);
  const finalSaleEndDate = moment.utc(props.finalSaleEndDate);
  const timeoutId = useRef<any>(null);
  const [tooltipTitle, setTooltipTitle] = useState("");
  const [timeCount, setTimeCount] = useState("");

  /*  const shouldNotDisplay = useRef(false);
  const [date1, setDate1] = useState<Date | null>(null);
  const [date2, setDate2] = useState<Date | null>(null);
  useEffect(() => {
    const updateTime = () => {
      switch (props.progress?.label) {
        case Bm_Progress_Satus.ComingSoon:
          if (latestLaunchSaleDate.isValid()) {
            setTooltipTitle(
              `Sale start on ${latestLaunchSaleDate.format(
                "LL [-] HH:mm [UTC]"
              )}`
            );
            setDate1(new Date());
            setDate2(latestLaunchSaleDate.toDate());
          }
          break;
        case Bm_Progress_Satus.SoldOut:
          shouldNotDisplay.current = true;
          if (latestLaunchSaleDate.isValid() && finalSaleEndDate.isValid()) {
            setTooltipTitle(`Estimated sale duration`);
            setDate1(latestLaunchSaleDate.toDate());
            setDate2(finalSaleEndDate.toDate());
          }
          break;

        case Bm_Progress_Satus.AlmostDone:
        case Bm_Progress_Satus.LastChance:
        case Bm_Progress_Satus.Open:
        case Bm_Progress_Satus.Launching:
          if (latestLaunchSaleDate.isValid()) {
            setTooltipTitle(`Time elapsed`);
            setDate1(new Date());
            setDate2(latestLaunchSaleDate.toDate());
          }
          break;
        default:
          break;
      }
      console.log(shouldNotDisplay)
      if (date1 && date2 && !shouldNotDisplay.current) {
        console.log('sdsdsd')
        timeoutId.current = setTimeout(updateTime, 1000);
      } else {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
        }
      }
    };

    updateTime();

    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, [props.progress]); // Only props.progress as a dependency

  if (date1 === null || date2 === null) return null; */
  //formatDateDifference(date1, date2)

  useEffect(() => {
    const updateTime = () => {
      let date1: Date | null = null;
      let date2: Date | null = null;
      switch (props.progress?.label) {
        case Bm_Progress_Satus.ComingSoon:
          if (latestLaunchSaleDate.isValid()) {
            setTooltipTitle(
              `Sale start on ${latestLaunchSaleDate.format(
                "LL [-] HH:mm [UTC]"
              )}`
            );
            date1 = new Date();
            date2 = latestLaunchSaleDate.toDate();
          }
          break;
        case Bm_Progress_Satus.SoldOut:
          if (latestLaunchSaleDate.isValid() && finalSaleEndDate.isValid()) {
            setTooltipTitle(`Estimated sale duration`);
            date1 = latestLaunchSaleDate.toDate();
            date2 = finalSaleEndDate.toDate();
          }
          break;

        case Bm_Progress_Satus.AlmostDone:
        case Bm_Progress_Satus.LastChance:
        case Bm_Progress_Satus.Open:
        case Bm_Progress_Satus.Launching:
          if (latestLaunchSaleDate.isValid()) {
            setTooltipTitle(`Time elapsed`);
            date1 = new Date();
            date2 = latestLaunchSaleDate.toDate();
          }
          break;
        default:
          break;
      }
      if (date1 && date2) {
        setTimeCount(formatDateDifference(date1, date2));
        if (props.progress?.label !== Bm_Progress_Satus.SoldOut) {
          timeoutId.current = setTimeout(updateTime, 1000);
        } else {
          if (timeoutId.current) {
            clearTimeout(timeoutId.current);
          }
        }
      }
    };

    updateTime();
    return () => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, [props.progress]);

  if (_.isEmpty(timeCount) || _.isEmpty(tooltipTitle)) return null;
  return (
    <Div status={props.progress?.label || Bm_Progress_Satus.Bizzare}>
      <Span>{timeCount}</Span>
      <Tooltip
        title={tooltipTitle}
        placement="right"
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#faf5ff",
              color: "#44337a",
            },
          },
          arrow: {
            sx: {
              color: "#faf5ff",
            },
          },
        }}
      >
        <IconButton sx={{ height: 16, width: 16, padding: 0 }}>
          <InfoIcon sx={{ fontSize: 16, color: "white" }} />
        </IconButton>
      </Tooltip>
    </Div>
  );
};

export default DaysCount;
