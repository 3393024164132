import {
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
  Button,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Chip,
} from "@mui/material";
import {
  ArithmeticComparison,
  Arithmetic_Operator,
  Bm_Progress_Satus,
  Bm_Status,
  MarketDataFilters,
  MarketDataMetricsFilter,
  Market_Data_Metrics_Values,
} from "graphql/schema/__generated__/graphql-types";
import { useSelector } from "redux/store";
import tw from "twin.macro";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DynamicFilterActions } from "redux/reducers/dynamic-filters";
import styled from "styled-components";
import { BetweenInterval, isValidNumber } from "./types";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import _ from "lodash";
import { initialState } from "redux/reducers/market-data";

const FilterHeader = tw.h2`text-lg font-semibold mb-1 mt-2`;
const NumberInput = styled.input`
  ${tw`inline p-1 w-full mr-2 bg-gray-100 rounded border border-gray-300 focus:ring-blue-500 focus:ring-2`}
`;

const ListingFilters = (props: {
  onChangeFilters?: (filters: MarketDataFilters) => void;
  onResetFilters?: () => void;
}) => {
  const dispatch = useDispatch();
  const emptyFunction = () => {};
  const { onChangeFilters = emptyFunction, onResetFilters = emptyFunction } =
    props;
  const initialFilters = useSelector((state) => state.marketDataItems.filters);
  const outFilters = useSelector((state) => state.dynamicFilters.filters);
  const [filters, setFilters] = useState<MarketDataFilters>(initialFilters);
  const [investInterval, setInvestInterval] = useState<BetweenInterval>({
    min: null,
    max: null,
  });
  const [metricInterval, setMetricInterval] = useState<BetweenInterval>({
    min: null,
    max: null,
  });
  const [selectedMetrics, setSelectedMetrics] =
    useState<Market_Data_Metrics_Values>(Market_Data_Metrics_Values.Coc);
  const PROFITABILITY_METRICS = filters.metrics || [];

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let status: Bm_Status[] = [];
    const one_status = event.target.name as Bm_Status;
    const old_Status = filters.status ?? [];
    if (event.target.checked) {
      status = [...old_Status, one_status];
    } else {
      status = old_Status.filter((the_status) => the_status !== one_status);
    }
    setFilters({ ...filters, status });
  };

  const handleProgressStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let progressStatus: Bm_Progress_Satus[] = [];
    const one_status = event.target.name as Bm_Progress_Satus;
    const old_Status = filters.progressStatus ?? [];
    if (event.target.checked) {
      progressStatus = [...old_Status, one_status];
    } else {
      progressStatus = old_Status.filter((the_status) => the_status !== one_status);
    }
    setFilters({ ...filters, progressStatus });
  }

  const handleOptionsInvestIntervaleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const numValue = value === "" ? null : Number(value);

    if (event.target.name === "min-invest") {
      setInvestInterval({
        ...investInterval,
        min: numValue,
        // min: parseFloat(event.target.value),
      });
    } else if (event.target.name === "max-invest") {
      setInvestInterval({
        ...investInterval,
        //max: parseFloat(event.target.value),
        max: numValue,
      });
    }
  };

  const handleOnApplyInvestInterval = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // Prevent the default button click behavior (optional)
    event.preventDefault();

    if (
      !isValidNumber(investInterval.min) &&
      !isValidNumber(investInterval.max)
    )
      return;

    let briicksUnitPrice: ArithmeticComparison | null = null;

    if (
      isValidNumber(investInterval.min) &&
      !isValidNumber(investInterval.max)
    ) {
      // Do something when button is clicked
      briicksUnitPrice = {
        numbers: [investInterval.min!],
        operator: Arithmetic_Operator.Gt,
      };
    } else if (
      !isValidNumber(investInterval.min) &&
      isValidNumber(investInterval.max)
    ) {
      briicksUnitPrice = {
        numbers: [investInterval.max!],
        operator: Arithmetic_Operator.Lt,
      };
    } else {
      briicksUnitPrice = {
        numbers: [investInterval.min!, investInterval.max!],
        operator: Arithmetic_Operator.Between,
      };
    }

    setFilters({ ...filters, briicksUnitPrice });
  };

  const handleProfitabilityFieldChange = (
    event: SelectChangeEvent<Market_Data_Metrics_Values>
  ) => {
    const value = event.target.value as Market_Data_Metrics_Values; // no need for typecasting her

    setSelectedMetrics(value);
  };

  const handleOptionsIncomeIntervaleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    const numValue = value === "" ? null : Number(value);

    if (event.target.name === "min-income") {
      setMetricInterval({
        ...metricInterval,
        //min: parseFloat(event.target.value),
        min: numValue,
      });
    } else if (event.target.name === "max-income") {
      setMetricInterval({
        ...metricInterval,
        //max: parseFloat(event.target.value),
        max: numValue,
      });
    }
  };

  const handleOnAddMetricsInterval = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // Prevent the default button click behavior (optional)
    event.preventDefault();

    if (
      !isValidNumber(metricInterval.min) &&
      !isValidNumber(metricInterval.max)
    )
      return;

    let metricValue: ArithmeticComparison | null = null;

    if (
      isValidNumber(metricInterval.min) &&
      !isValidNumber(metricInterval.max)
    ) {
      metricValue = {
        numbers: [metricInterval.min!],
        operator: Arithmetic_Operator.Gt,
      };
    } else if (
      !isValidNumber(metricInterval.min) &&
      isValidNumber(metricInterval.max)
    ) {
      metricValue = {
        numbers: [metricInterval.max!],
        operator: Arithmetic_Operator.Lt,
      };
    } else {
      metricValue = {
        numbers: [metricInterval.min!, metricInterval.max!],
        operator: Arithmetic_Operator.Between,
      };
    }

    const metrics = filters.metrics || [];
    setFilters({
      ...filters,
      metrics: [...metrics, { field: selectedMetrics, value: metricValue }],
    });
    setMetricInterval({
      min: null,
      max: null,
    });
  };

  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let countries: string[] = [];
    const countryCode = event.target.name;
    const old_countries = filters.countries ?? [];
    if (event.target.checked) {
      countries = [...old_countries, countryCode];
    } else {
      countries = old_countries.filter((country) => country !== countryCode);
    }
    setFilters({ ...filters, countries });
  };

  const handleMakerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let makers: string[] = [];
    const maker = event.target.name;
    const old_makers = filters.makers ?? [];
    if (event.target.checked) {
      makers = [...old_makers, maker];
    } else {
      makers = old_makers.filter((theMaker) => theMaker !== maker);
    }
    setFilters({ ...filters, makers });
  };

  const handleResetFilters = () => {
    setMetricInterval({
      min: null,
      max: null,
    });
    setInvestInterval({
      min: null,
      max: null,
    });
    setFilters({ ...initialState.filters });
    onResetFilters();
  };

  //Effects
  useEffect(() => {
    dispatch(DynamicFilterActions.getMarketDataFiltersRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!_.isEqual(filters, initialFilters)) onChangeFilters(filters);
  }, [filters]);

  return (
    <>
      {false && outFilters.status.length > 0 && (
        <>
          <FilterHeader>Status</FilterHeader>
          {outFilters.status.map((status: Bm_Status, index: number) => (
            <Box display="block">
              <FormControlLabel
                sx={{ height: 30 }}
                key={index}
                control={
                  <Checkbox
                    checked={(filters.status ?? []).includes(status)}
                    onChange={handleStatusChange}
                    name={status}
                  />
                }
                label={
                  <Typography variant="body2">
                    {status.replaceAll("_", " ")}
                  </Typography>
                }
              />
            </Box>
          ))}
        </>
      )}

      {outFilters.progressStatus.length > 0 && (
        <>
          <FilterHeader>Progress Status</FilterHeader>
          {outFilters.progressStatus.map(
            (status: Bm_Progress_Satus, index: number) => (
              <Box display="block">
                <FormControlLabel
                  sx={{ height: 30 }}
                  key={index}
                  control={
                    <Checkbox
                      checked={(filters.progressStatus ?? []).includes(status)}
                      onChange={handleProgressStatusChange}
                      name={status}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {status.replaceAll("_", " ")}
                    </Typography>
                  }
                />
              </Box>
            )
          )}
        </>
      )}

      <FilterHeader>Briick price</FilterHeader>
      <Stack spacing={1}>
        <NumberInput
          name="min-invest"
          id="minInvest-min"
          type="number"
          placeholder="Min"
          onChange={handleOptionsInvestIntervaleChange}
          //value={investInterval.min || ''}
          value={
            investInterval.min === null || investInterval.min === undefined
              ? ""
              : investInterval.min
          }
        />
        <NumberInput
          name="max-invest"
          id="maxInvest-max"
          type="number"
          placeholder="Max"
          onChange={handleOptionsInvestIntervaleChange}
          //value={investInterval.max || ''}
          value={
            investInterval.max === null || investInterval.max === undefined
              ? ""
              : investInterval.max
          }
        />

        <Button
          sx={tw`bg-primary-500`}
          onClick={handleOnApplyInvestInterval}
          disabled={
            !isValidNumber(investInterval.min) &&
            !isValidNumber(investInterval.max)
          }
          variant="contained"
          startIcon={<SearchIcon />}
        >
          Search
        </Button>
      </Stack>

      <FilterHeader css={tw`mt-4`}>Metrics</FilterHeader>
      <FormControl
        variant="outlined"
        size="small"
        sx={{ ...tw`mt-2`, ...{ marginBottom: 1 } }}
        fullWidth
      >
        <Select
          labelId="sort-by-label"
          value={selectedMetrics}
          onChange={handleProfitabilityFieldChange}
        >
          <MenuItem
            disabled={PROFITABILITY_METRICS.some(
              (obj) => obj.field === Market_Data_Metrics_Values.Coc
            )}
            value={Market_Data_Metrics_Values.Coc}
          >
            Cash on cash
          </MenuItem>
          <MenuItem
            disabled={PROFITABILITY_METRICS.some(
              (obj) => obj.field === Market_Data_Metrics_Values.Appreciation
            )}
            value={Market_Data_Metrics_Values.Appreciation}
          >
            Appreciation
          </MenuItem>
          <MenuItem
            disabled={PROFITABILITY_METRICS.some(
              (obj) => obj.field === Market_Data_Metrics_Values.Roi
            )}
            value={Market_Data_Metrics_Values.Roi}
          >
            R.O.I
          </MenuItem>
        </Select>
      </FormControl>

      {PROFITABILITY_METRICS.length > 0 && (
        <Stack spacing={1} css={tw`mb-2`}>
          {PROFITABILITY_METRICS.map(
            (metric: MarketDataMetricsFilter, index: number) => (
              <Chip
                key={index}
                label={((m: MarketDataMetricsFilter): string => {
                  let title = "";
                  let symbole = "";
                  if (m.field === Market_Data_Metrics_Values.Coc) {
                    title = "C.O.C";
                    symbole = "%";
                  } else if (m.field === Market_Data_Metrics_Values.Roi) {
                    title = "R.O.I";
                    symbole = "%";
                  } else if (
                    m.field === Market_Data_Metrics_Values.Appreciation
                  ) {
                    title = "Appr.";
                    symbole = "%";
                  }

                  let suffix = "";
                  if (m.value?.operator === Arithmetic_Operator.Lt)
                    suffix = `< ${m.value?.numbers[0]}${symbole}`;
                  else if (m.value?.operator === Arithmetic_Operator.Gt)
                    suffix = `> ${m.value?.numbers[0]}${symbole}`;
                  else
                    suffix = `${m.value?.numbers[0]}${symbole} to ${m.value?.numbers[1]}${symbole}`;
                  return `${title} ${suffix}`;
                })(metric)}
                onDelete={() => {
                  setFilters({
                    ...filters,
                    metrics: PROFITABILITY_METRICS.filter(
                      (obj) => obj.field !== metric.field
                    ),
                  });
                }}
              />
            )
          )}
        </Stack>
      )}

      <Stack spacing={1}>
        <NumberInput
          name="min-income"
          id="minIncome-min"
          type="number"
          placeholder="Min"
          onChange={handleOptionsIncomeIntervaleChange}
          disabled={
            PROFITABILITY_METRICS.some(
              (obj) => obj.field === Market_Data_Metrics_Values.Coc
            ) &&
            PROFITABILITY_METRICS.some(
              (obj) =>
                obj.field === Market_Data_Metrics_Values.Roi &&
                PROFITABILITY_METRICS.some(
                  (obj) => obj.field === Market_Data_Metrics_Values.Appreciation
                )
            )
          }
          // value={incomeInterval.min || ''}
          value={
            metricInterval.min === null || metricInterval.min === undefined
              ? ""
              : metricInterval.min
          }
        />
        <NumberInput
          name="max-income"
          id="maxIncome-max"
          type="number"
          placeholder="Max"
          onChange={handleOptionsIncomeIntervaleChange}
          disabled={
            PROFITABILITY_METRICS.some(
              (obj) => obj.field === Market_Data_Metrics_Values.Coc
            ) &&
            PROFITABILITY_METRICS.some(
              (obj) =>
                obj.field === Market_Data_Metrics_Values.Roi &&
                PROFITABILITY_METRICS.some(
                  (obj) => obj.field === Market_Data_Metrics_Values.Appreciation
                )
            )
          }
          //value={incomeInterval.max || ''}
          value={
            metricInterval.max === null || metricInterval.max === undefined
              ? ""
              : metricInterval.max
          }
        />

        <Button
          sx={tw`bg-primary-500`}
          onClick={handleOnAddMetricsInterval}
          disabled={
            (!isValidNumber(metricInterval.min) &&
              !isValidNumber(metricInterval.max)) ||
            (PROFITABILITY_METRICS.some(
              (obj) => obj.field === Market_Data_Metrics_Values.Coc
            ) &&
              PROFITABILITY_METRICS.some(
                (obj) =>
                  obj.field === Market_Data_Metrics_Values.Roi &&
                  PROFITABILITY_METRICS.some(
                    (obj) =>
                      obj.field === Market_Data_Metrics_Values.Appreciation
                  )
              ))
          }
          variant="contained"
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      </Stack>

      {outFilters.countries.length > 0 && (
        <>
          <FilterHeader>Countries</FilterHeader>
          {outFilters.countries.map((country: string, index: number) => (
            <FormControlLabel
              sx={{ height: 30 }}
              key={index}
              control={
                <Checkbox
                  checked={(filters.countries ?? []).includes(country)}
                  onChange={handleCountryChange}
                  name={country}
                />
              }
              label={<Typography variant="body2">{country}</Typography>}
            />
          ))}
        </>
      )}

      {outFilters.makers.length > 0 && (
        <>
          <FilterHeader>Briickmarker</FilterHeader>
          {outFilters.makers.map((maker: string, index: number) => (
            <FormControlLabel
              sx={{ height: 30 }}
              key={index}
              control={
                <Checkbox
                  checked={(filters.makers ?? []).includes(maker)}
                  onChange={handleMakerChange}
                  name={maker}
                />
              }
              label={<Typography variant="body2">{maker}</Typography>}
            />
          ))}
        </>
      )}

      <Button
        fullWidth
        size="small"
        sx={{ marginTop: 1 }}
        variant="contained"
        color="info"
        onClick={handleResetFilters}
      >
        Reset filters
      </Button>
    </>
  );
};
export default ListingFilters;
