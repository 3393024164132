import tw from 'twin.macro';
import NumberFormat from 'react-number-format';
import Tooltip from '@mui/material/Tooltip';
import { Divider } from '@mui/material';
import { Investment, Maybe } from 'graphql/schema/__generated__/graphql-types';

const Container = tw.div`
col-span-3 pr-1
`;
const Heading2 = tw.h2`
font-medium text-base text-secondary-900 mr-1
`;

const Heading3 = tw.h3`
font-medium text-sm text-secondary-900 mr-1
`;

/*const Val = tw.span`
flex-grow px-2 flex justify-end text-sm 
text-secondary-500 whitespace-nowrap truncate 
`*/
const Val = tw.span`
flex-grow overflow-hidden text-right text-sm 
text-blue-500 whitespace-nowrap truncate 
px-2
`;

const Row = tw.div`
flex flex-row
`;

const InvestmentView = (props: { investment?: Maybe<Investment> }) => {
  const maxPurchase = props.investment?.limit.maxPurchase.amount;
  const assetType = props.investment?.contract.assetType;
  return (
    <Container>
      <Heading2>Investment</Heading2>
      <Divider variant={undefined} css={tw`bg-secondary-900 mr-2!`} />
      <Row>
        <Heading3>Asset type</Heading3>
        <Val>{assetType}</Val>
      </Row>

      <Row>
        <Heading3>Maximum</Heading3>
        <Val>
          <NumberFormat
            value={maxPurchase}
            decimalScale={2}
            fixedDecimalScale
            displayType={'text'}
            thousandSeparator={' '}
            prefix={'$ '}
            renderText={(value: string) => <span>{value}</span>}
          />
        </Val>
      </Row>
      <Row>
        <Heading3>Payment</Heading3>
        <Val css={tw`text-gray-500`}>no data</Val>
      </Row>
      {false && (
        <>
          <Row>
            <Heading3>Networks</Heading3>
            <Tooltip
              title="Ethereum, Terra, Algorand, VISA, MasterCard, Solana, Cardano, Enecuum, Ethereum, Terra, Algorand, VISA, MasterCard, Solana, Cardano, Enecuum"
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: '#44337a',
                    color: '#faf5ff',
                    fontSize: 12,
                  },
                },
                arrow: {
                  sx: {
                    color: '#44337a',
                  },
                },
              }}
            >
              <Val>
                Ethereum, Terra, Algorand, VISA, MasterCard, Solana, Cardano, Enecuum, Ethereum, Terra, Algorand, VISA,
                MasterCard, Solana, Cardano, Enecuum
              </Val>
            </Tooltip>
          </Row>
          <Row>
            <Heading3>Currency</Heading3>
            <Tooltip
              title="USD, EUR, USDC, SOL, USDX, TL, DZ"
              arrow
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: '#44337a',
                    color: '#faf5ff',
                    fontSize: 12,
                  },
                },
                arrow: {
                  sx: {
                    color: '#44337a',
                  },
                },
              }}
            >
              <Val>USD, EUR, USDC, SOL, USDX, TL, DZ</Val>
            </Tooltip>
          </Row>
        </>
      )}
    </Container>
  );
};

export default InvestmentView;
