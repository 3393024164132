import { useState } from 'react';
//@ts-ignore
import { css } from 'styled-components/cssprop'; /* eslint-disable-line */
import tw from 'twin.macro';
import { SectionHeading } from 'components/misc/Headings';
import { PrimaryLink as PrimaryLinkBase } from 'components/misc/Links.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons';
import { ReactComponent as ChevronLeftIcon } from 'feather-icons/dist/icons/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right-icon.svg';
import styled from 'styled-components';
import { SliderBase as Slider } from 'components/misc/React-Slick';
import _ from 'lodash';

const sliderSettings = {
  arrows: false,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
      },
    },

    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;
const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center justify-center xl:hidden`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-end items-center text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;
const ShowAll = tw.div`flex justify-center xl:justify-end`;

const GoToMarket = tw.div`flex justify-center pt-32`;

const PrimaryButton = styled(PrimaryButtonBase)<{ as: string }>`
  ${tw`mt-auto sm:text-lg rounded-none py-1 sm:py-2 mb-4`}
`;

const HighlightedListingSection = (props: { content: IHighlightedListingSection }) => {
  //@ts-ignore
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);

  return (
    <>
      <Container>
        <Content>
          <HeadingWithControl>
            <Heading>{props.content.Title}</Heading>
            <ShowAll>
              <PrimaryLink as="a" href={props.content.MoreContent.link}>
                {props.content.MoreContent.text} <ArrowRightIcon />
              </PrimaryLink>
            </ShowAll>
            <Controls>
              <PrevButton onClick={sliderRef?.slickPrev}>
                <ChevronLeftIcon />
              </PrevButton>
              <NextButton onClick={sliderRef?.slickNext}>
                <ChevronRightIcon />
              </NextButton>
            </Controls>
          </HeadingWithControl>

          <Slider ref={setSliderRef} {...sliderSettings}></Slider>

          <GoToMarket>
            <PrimaryButton as="a" href={props.content.CTAButton.link} css={tw`py-2 sm:py-4`}>
              {props.content.CTAButton.text}
            </PrimaryButton>
          </GoToMarket>
        </Content>
      </Container>
    </>
  );
};

export default HighlightedListingSection;

export interface IHighlightedListingSection {
  Title: string;
  MoreContent: {
    text: string;
    link: string;
  };
  CTAButton: {
    link: string;
    text: string;
  };
}
