import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import Routes from './routes';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_MEASUREMENT_ID as string;
ReactGA.initialize(TRACKING_ID);

Modal.setAppElement('#root');

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
