import LoftyIcon from 'images/sources-logo/Lofty.svg';
import RealTIcon from 'images/sources-logo/RealT.svg';
// import ReentalIcon from 'images/sources-logo/Reental.svg'
// import VestaEquityIcon from 'images/sources-logo/VestaEquity.svg'
// import BrickXIcon from 'images/sources-logo/BrickX.svg'
// import BrickSIcon from 'images/sources-logo/BrickS.svg'
// import LandShareIcon from 'images/sources-logo/LandShare.svg'
// import GrapenInvestIcon from 'images/sources-logo/GrapenInvest.svg'
// import KatapulteIcon from 'images/sources-logo/Katapulte.svg'
// import LaPremiereBriqueIcon from 'images/sources-logo/LaPremiereBrique.svg'
// import SmartCrowdIcon from 'images/sources-logo/SmartCrowd.svg'
// import SmartCrowdDirectIcon from 'images/sources-logo/SmartCrowdDirect.svg'
// import InvestDubaiIcon from 'images/sources-logo/InvestDubai.svg'
// import VairtIcon from 'images/sources-logo/Vairt.svg'
// import AqarChainIcon from 'images/sources-logo/AqarChain.svg'

export const SourcesIcon = (source: string): string | undefined => {
  if (source.toLowerCase() === 'lofty ai') return LoftyIcon;
  if (source.toLowerCase() === 'real token') return RealTIcon;
  // if(source === "Brickx") return BrickXIcon
  // if(source === "BrickS") return BrickSIcon
  // if(source === "Reental") return ReentalIcon
  // if(source === 'Vesta-Equity') return VestaEquityIcon
  // if(source === 'Land-Share') return LandShareIcon
  // if(source === 'Grapen-Invest') return GrapenInvestIcon
  // if(source === 'Katapulte') return KatapulteIcon
  // if(source === 'La-Premiere-Brique') return LaPremiereBriqueIcon
  // if(source === 'Smart-Crowd') return SmartCrowdIcon
  // if(source === 'Smart-Crowd-Pakistan') return SmartCrowdIcon
  // if(source === 'Smart-Crowd-Direct') return SmartCrowdDirectIcon
  // if(source === 'Invest-Dubai') return InvestDubaiIcon
  // if(source === 'Vairt') return VairtIcon
  // if(source === 'Aqar-Chain') return AqarChainIcon

  return undefined;
};
