import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Financial,
  FloatMetadata,
} from "graphql/schema/__generated__/graphql-types";
import { Fragment } from "react";
import NumberFormat from "react-number-format";
import tw from "twin.macro";

const ValuesViews = (props: { financial: Financial | undefined }) => {
  const operatingExpenses = props.financial?.operatingExpenses;
  const groups: { title: string; items: FloatMetadata[] | undefined }[] = [
    {
      title: "Utilities",
      items: operatingExpenses?.utilities,
    },
    {
      title: "Maintenance",
      items: operatingExpenses?.maintenance,
    },
    {
      title: "Insurance",
      items: operatingExpenses?.insurance,
    },
    {
      title: "Taxes",
      items: operatingExpenses?.taxes,
    },
    {
      title: "Administrative Fees",
      items: operatingExpenses?.administrativeFees,
    },
  ];

  const total = operatingExpenses?.totalCost;

  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: 850, alignSelf: "center", marginTop: 2 }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  "& .MuiTableCell-root": {
                    padding: 1,
                    //border: 1
                  },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {groups.map((e, index: number) => (
                    <Fragment key={index}>
                      <TableRow key={index}>
                        <TableCell component="th" scope="row" colSpan={2}>
                          <Typography
                            variant="body1"
                            sx={tw`text-green-500 font-bold`}
                          >
                            {e.title}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {e.items === undefined ? (
                        <TableRow>
                          <TableCell component="th">no data</TableCell>
                        </TableRow>
                      ) : (
                        e.items.map((o, index: number) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {o.key}
                            </TableCell>
                            <TableCell align="right" sx={tw`capitalize`}>
                              <NumberFormat
                                value={o.value}
                                decimalScale={2}
                                fixedDecimalScale
                                displayType={"text"}
                                thousandSeparator={" "}
                                prefix={"$ "}
                                renderText={(value: string) => (
                                  <span>{value}</span>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </Fragment>
                  ))}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="body1"
                        sx={tw`text-blue-500 font-bold`}
                      >
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="right" sx={tw`text-blue-500 font-bold`}>
                      <Typography
                        variant="body1"
                        sx={tw`text-blue-500 font-bold`}
                      >
                        {total === undefined ? (
                          "no data"
                        ) : (
                          <NumberFormat
                            value={total}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={"text"}
                            thousandSeparator={" "}
                            prefix={"$ "}
                            renderText={(value: string) => <span>{value}</span>}
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ValuesViews;
