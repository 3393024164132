import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Investment } from 'graphql/schema/__generated__/graphql-types';
import NumberFormat from 'react-number-format';
import tw from 'twin.macro';

const Title = tw.h2`
  text-blue-500 font-medium text-2xl mt-2
  `;

const Limits = (props: { investment: Maybe<Investment> }) => {
  const offering = props.investment?.offering;
  const limit = props.investment?.limit;
  const percent = (unit?: number, total?: number): number | null => {
    if (unit === undefined || total === undefined) return null;
    return (unit * 100) / total;
  };

  const DATA: {
    header: string[];
    values: { title: string; briicks: number | null; amount: number | null; percent: number | null }[];
  }[] = [
    {
      header: ['Purchase', 'briicks', 'amount', '%'],
      values: [
        {
          title: 'Minimum',
          briicks: limit?.minPurchase?.count ?? null,
          amount: limit?.minPurchase?.amount ?? null,
          percent: percent(limit?.minPurchase?.count, offering?.totalOffering?.count),
        },
        {
          title: 'Maximum',
          briicks: limit?.maxPurchase?.count ?? null,
          amount: limit?.maxPurchase?.amount ?? null,
          percent: percent(limit?.maxPurchase?.count, offering?.totalOffering?.count),
        },
      ],
    },
    {
      header: ['Takers', 'number', 'amount', '%'],
      values: [
        {
          title: 'Minimum',
          briicks: limit?.minTakers?.takerCount ?? null,
          amount: limit?.minTakers?.investmentAmount ?? null,
          percent: percent(limit?.minTakers.investmentAmount, offering?.totalOffering?.amount),
        },
        {
          title: 'Maximum',
          briicks: limit?.maxTakers.takerCount ?? null,
          amount: limit?.maxTakers?.investmentAmount ?? null,
          percent: percent(limit?.maxTakers?.investmentAmount, offering?.totalOffering?.amount),
        },
      ],
    },
  ];

  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>Limits</Title>
        <Box flexDirection="column" sx={{ display: 'flex', justifyContent: 'center' }}>
          {DATA.map((e, index: number) => (
            <Box key={index} sx={{ width: 850, alignSelf: 'center', marginTop: 2 }}>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    minWidth: 650,
                    '& .MuiTableCell-root': {
                      padding: 1,
                      //border: 1
                    },
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      {e.header.map((o, index: number) => (
                        <TableCell key={index} align={index === 0 ? 'left' : 'right'}>
                          <Typography
                            variant={index === 0 ? 'h6' : 'body1'}
                            component="div"
                            sx={index === 0 ? tw`capitalize text-green-500` : tw`capitalize font-bold`}
                          >
                            {o}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {e.values.map((e, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {e.title}
                        </TableCell>
                        <TableCell align="right" sx={tw`capitalize`} width={200}>
                          {e.briicks !== null ? (
                            <NumberFormat
                              value={e.briicks}
                              decimalScale={0}
                              fixedDecimalScale
                              displayType={'text'}
                              thousandSeparator={' '}
                              renderText={(value: string) => <span>{value}</span>}
                            />
                          ) : (
                            '--'
                          )}
                        </TableCell>
                        <TableCell align="right" sx={tw`capitalize`} width={200}>
                          {e.amount !== null ? (
                            <NumberFormat
                              value={e.amount}
                              decimalScale={2}
                              fixedDecimalScale
                              displayType={'text'}
                              thousandSeparator={' '}
                              prefix="$ "
                              renderText={(value: string) => <span>{value}</span>}
                            />
                          ) : (
                            '--'
                          )}
                        </TableCell>
                        <TableCell align="right" sx={tw`capitalize`} width={200}>
                          {e.percent !== null ? (
                            <NumberFormat
                              value={e.percent}
                              decimalScale={2}
                              fixedDecimalScale
                              displayType={'text'}
                              thousandSeparator={' '}
                              suffix=" %"
                              renderText={(value: string) => <span>{value}</span>}
                            />
                          ) : (
                            '--'
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Limits;
