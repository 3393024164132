import Header from 'components/header';
import Footer from 'components/footer';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import tw from 'twin.macro';
import { Box, Button, TextField } from '@mui/material';

const Container = tw(ContainerBase)`-mx-8 px-4 `;
const Content = tw.div`max-w-screen-sm mx-auto py-20 lg:py-24`;
const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;
const Divider = tw.hr`
`;

const SignIn = () => {
  const handleSubmit = () => {};
  return (
    <>
      <Header />
      <Container>
        <Content>
          <Headline>Forgot password</Headline>
          <Divider />
          <Box sx={tw`flex justify-center`}>
            <Box component="form" width={400} onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="off"
                autoFocus
              />

              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 2 }}>
                <Button color="error" size="large" type="submit" variant="contained" sx={{ width: 200 }}>
                  Recovery
                </Button>
              </Box>
            </Box>
          </Box>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default SignIn;
