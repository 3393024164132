import { Box } from "@mui/material";
import { Property } from "graphql/schema/__generated__/graphql-types";
import tw from "twin.macro";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const REACT_APP_GOOGLE_MAPS: string = process.env.REACT_APP_GOOGLE_MAPS ?? "";

const mapStyles = {
  height: "100%",
  width: "100%",
};

const MapsView = (props: { property: Property }) => {
  const { location } = props.property;
  const defaultCenter = {
    lat: location?.lat || 0,
    lng: location?.lng || 0,
  };
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>Maps</Title>
        <Box sx={{ flexGrow: 1, height: 500, marginTop: 2 }}>
          <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS}>
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={13}
              center={defaultCenter}
            >
              {location?.lat && location?.lng && (
                <Marker position={defaultCenter} />
              )}
            </GoogleMap>
          </LoadScript>
        </Box>
      </Box>
    </>
  );
};

export default MapsView;
