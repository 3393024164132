import tw from 'twin.macro';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
const Div = tw.div`
bg-white overflow-hidden flex items-center rounded-md ml-1 mb-1 p-1 px-2 flex-row
text-black uppercase whitespace-nowrap truncate
`;

//tw`absolute w-24 rounded-md text-center mx-2 my-2 px-2 py-1 text-white uppercase top-0 left-0`
export const TakerRestrictions = () => {
  return (
    <Div>
      <AssignmentIndIcon sx={{ fontSize: 18, marginRight: 0.5 }} />
      Restriction
    </Div>
  );
};

export default TakerRestrictions;
