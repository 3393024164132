import { IFullAdsSection } from 'components/common/types';
import { Resources } from 'graphql/schema/__generated__/graphql-types';
import { ThemeProvider } from '@mui/material';
import SectionContainer from 'components/article/section-container';
import { theme } from '../../theme';
import Provider, { ARTICLE_TITLE as ProviderName } from './provider';
import Documents, { ARTICLE_TITLE as DocumentsName } from './doccuments';
import Payment, { ARTICLE_TITLE as PaymentName } from './payment';
import { Maybe } from 'graphql/jsutils/Maybe';

export const elements: IFullAdsSection[] = [
  {
    name: ProviderName,
    element: Provider,
  },
  {
    name: DocumentsName,
    element: Documents,
  },
  {
    name: PaymentName,
    element: Payment,
  },
];

const ResourceSection = (props: {
  resources: Maybe<Resources>;
  viewPortObserver: (articleName: string, isVisible: boolean) => void;
}) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <SectionContainer>
          {elements.map((e, index) => {
            const V = e.element;
            return <V resources={props.resources} id={e.name} key={index} viewPortObserver={props.viewPortObserver} />;
          })}
        </SectionContainer>
      </ThemeProvider>
    </>
  );
};

export default ResourceSection;
