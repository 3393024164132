import tw from 'twin.macro';
import { SourcesIcon } from 'components/misc/SourcesIcon';
import { Maybe } from 'graphql/schema/__generated__/graphql-types';

const Container = tw.div`flex justify-end items-center`;
const Header = tw.div`
font-medium text-gray-600 pr-2
`;
const BriicksMakerLogo = tw.img`
w-auto h-6
`;

const ProposedBy = (props: { maker?: Maybe<string> }) => {
  return (
    <Container>
      <Header>Proposed by</Header>
      <BriicksMakerLogo src={SourcesIcon(props.maker ?? "")} />
    </Container>
  );
};

export default ProposedBy;
