import { MarketDataFilters, MarketDataSortBy } from "graphql/schema/__generated__/graphql-types";

export interface BetweenInterval {
  min: number | null;
  max: number | null;
}

export function isValidNumber(x: any) {
  return x !== null && typeof x === "number" && !isNaN(x);
}

export type ApiInputType = {
  page: number;
  limit: number;
  filters: MarketDataFilters;
  sortBy: MarketDataSortBy;
};

export type SetApiInputFunction = (value: ApiInputType) => void;
