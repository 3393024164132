import React from 'react';
import tw from 'twin.macro';

const Container = tw.div`
flex pt-4 flex-col
`;

const SectionContainer = (props: { children?: React.ReactNode; setVisibleArticle?: (articleName: string) => void }) => {
  return <Container {...props}>{props.children}</Container>;
};

export default SectionContainer;
