export const BriiksMakers = [
  {
    name: '',
    logo: 'https://cdn-realt.junglewp.net/wp-content/uploads/2019/04/RealT_Logo.svg',
    coordinates: [2.338794, 48.873514],
  },
  {
    coordinates: [103.855227, 1.340768],
    name: '',
    logo: 'https://d153mhnvznv9d5.cloudfront.net/eyJidWNrZXQiOiJhc3NldHMtcHJvZC1hc3NldHMtMXFhcm1vbmZlZnp0NSIsImtleSI6ImJsb2NraW1tby9sb2dvLWhlYWRlci5wbmciLCJlZGl0cyI6e319',
  },
  {
    name: '',
    logo: 'https://d3dvbnsgzi5cxr.cloudfront.net/integration/grapen-invest/logo-couleur-v3-grapen-invest.png',
    coordinates: [-105.102978, 58.515617],
  },
  {
    name: '',
    logo: 'https://landshare.io/wp-content/uploads/2022/06/Group-23628.svg',
    coordinates: [-101.185136, 39.361233],
  },
  {
    name: '',
    logo: 'https://content.app-sources.com/s/95575233933083804/uploads/Images/Untitled_design_-_2022-06-06T110235.784-4524163.png',
    coordinates: [-1.911507, 53.046392],
  },
  {
    name: '',
    logo: 'https://www.lofty.ai/static/media/logo-light.ff9fcbf0916e664961e966b23ce0dcc5.svg',
    coordinates: [39.924885, 32.825242],
  },
  {
    name: '',
    logo: 'https://vave.io/assets/img/eggle-new/vave-dark-logo.svg',
    coordinates: [39.924885, 32.825242],
  },
  {
    name: '',
    logo: 'https://solidblock.co/static/media/solidblock-logo-dark.aa1b1159.svg',
    coordinates: [39.924885, 32.825242],
  },
  {
    name: '',
    logo: 'https://marketplace.omni-psi.com/static/media/latestomnilogo.65513efea28985e57ec9.png',
    coordinates: [39.924885, 32.825242],
  },
  {
    name: '',
    logo: 'https://assets.website-files.com/62c74ee2c155535e38a00fc3/62def0d87c6fd84db1c1d93b_OwnProp%20Logo%20-%20Blue.svg',
    coordinates: [39.924885, 32.825242],
  },
];
