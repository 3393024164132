import { Box, Typography } from '@mui/material';
import { Investment } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const Strategy = (props: { investment: Investment }) => {
  const paragraphs: string[] = [];
  if (props.investment?.strategy?.trim().toLowerCase() === 'long-term') {
    paragraphs.push(`A long-term strategy in real estate involves holding onto a property for a significant period of time, often
    years.`);
    paragraphs.push(`This approach not only allows for potential capital appreciation and compounding rental income, but it also
    represents a more secure investment as it tends to reduce exposure to short-term market fluctuations`);
  } else {
    paragraphs.push(`no data`);
  }
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>
          Strategy: <Title css={tw`inline text-green-500 uppercase`}>{props.investment?.strategy || 'no data'}</Title>
        </Title>

        {paragraphs.map((e, index: number) => (
          <Typography key={index} variant="body1" gutterBottom sx={{ marginTop: 1 }}>
            {e}
          </Typography>
        ))}
      </Box>
    </>
  );
};

export default Strategy;
