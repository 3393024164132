import styled from 'styled-components';
import tw from 'twin.macro';

const Grid = tw.div`grid grid-cols-6 gap-4 md:gap-8`;
const Card = styled.div`
  ${tw`flex flex-col items-center justify-center px-3 py-5 border-2 border-dashed border-primary-500 border-r-0 border-l-0`}
  .imageContainer {
    img {
      ${tw`w-full h-32 `}
    }
  }
`;
//
const BriickCard = styled(Card)<{ fullBriick: boolean; left: boolean; right: boolean }>`
  ${(props) => props.fullBriick && tw`col-span-2 rounded-lg border-r-2 border-l-2 `}
  ${(props) => props.left && tw`rounded-l-lg border-l-2`}
${(props) => props.right && tw`rounded-r-lg border-r-2`}
`;

const ListBriicksMaker = (props: { makers: { logo: string }[] }) => {
  let nextBorder: 'left' | 'right' = 'right';
  let nextBriickIndex = 3;
  return (
    <Grid>
      {props.makers.map(({ logo }, index) => {
        const left = nextBriickIndex === index && nextBorder === 'left';
        const right = nextBriickIndex === index && nextBorder === 'right';
        const fullBriick = !left && !right;
        if (left) {
          nextBriickIndex = index + 4;
          nextBorder = 'right';
        } else if (right) {
          nextBriickIndex = index + 3;
          nextBorder = 'left';
        }
        //console.log(index, fullBriick)

        return (
          <BriickCard left={left} right={right} fullBriick={fullBriick}>
            <span className="imageContainer">
              <img src={logo} alt="" />
            </span>
          </BriickCard>
        );
      })}
    </Grid>
  );
};

export default ListBriicksMaker;
