import tw from 'twin.macro';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Bm_Progress_Satus } from 'graphql/schema/__generated__/graphql-types';

// const Container = styled.div`
// ${tw`h-8 w-8 absolute top-0 right-0 mx-2 my-2`}
// text {
//     ${tw`text-4xl!`}
//     fill: #fff !important;
//   }
// `

const Container = styled.div`
  ${tw`h-8 w-8 absolute top-0 right-0 mx-2 my-2`}

  .CircularProgressbar-text {
    dominant-baseline: central;
    text-anchor: middle;
    fill: #fff;
    ${tw`text-4xl! font-bold!`}
  }
`;

const SalesProgress = (props: { progress: number | null; status: Bm_Progress_Satus }) => {
  let color = '#22c55e';
  const textColor = '#fff';
  if (props.status === Bm_Progress_Satus.Launching) color = '#22c55e';
  else if (props.status === Bm_Progress_Satus.AlmostDone) color = '#f97316';
  else if (props.status === Bm_Progress_Satus.SoldOut) color = '#ef4444';
  else if (props.status === Bm_Progress_Satus.ComingSoon) return null; //color = '#4299e1'
  else if (props.status === Bm_Progress_Satus.Open) color = '#38b2ac';
  else if (props.status === Bm_Progress_Satus.LastChance) color = '#ecc94b';
  else if (props.status === Bm_Progress_Satus.NotFunded) color = '#7c8ba1';

  if (props.progress === null) return null;

  return (
    <Container>
      <CircularProgressbar
        value={Math.floor(props.progress)}
        text={`${Math.floor(props.progress)}%`}
        strokeWidth={50}
        styles={buildStyles({
          strokeLinecap: 'butt',
          backgroundColor: color,
          textColor: textColor,
          trailColor: `${color}A0`,
          pathColor: color,
        })}
      />
    </Container>
  );
};

export default SalesProgress;
