import tw from 'twin.macro';
import { Container as ContainerBase, ContentWithPaddingXl } from 'components/misc/Layouts';
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography';

const Container = tw(ContainerBase)`my-4 lg:my-6 text-secondary-700 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-700 text-center`;
const Description = tw(SectionDescription)`text-gray-700 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`;
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`;
const StatKey = tw.div`text-xl font-medium`;
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`;

const StatsSection = (props: { content: IStatsSection }) => {
  return (
    <>
      <Container>
        <ContentWithPaddingXl>
          <HeadingContainer>
            {props.content.SubTitle && <Subheading>{props.content.SubTitle}</Subheading>}
            <Heading>{props.content.Title}</Heading>
            {props.content.Description && <Description>{props.content.Description}</Description>}
          </HeadingContainer>
          <StatsContainer>
            {props.content.Stats.map((stat, index) => (
              <Stat key={index}>
                <StatValue>{stat.value}</StatValue>
                <StatKey>{stat.label}</StatKey>
              </Stat>
            ))}
          </StatsContainer>
        </ContentWithPaddingXl>
      </Container>
    </>
  );
};

export default StatsSection;

export interface IStatsSection {
  Title: string;
  SubTitle: string;
  Description: string;
  Stats: {
    label: string;
    value: string;
  }[];
}
