//import { MapContainer, TileLayer } from 'react-leaflet'
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import MapContries from 'components/misc/map-countries.json';

/*
export const LeafMap = () => {


    return <>
        <MapContainer
            center={[51.505, -0.09]}
            zoom={13}
            scrollWheelZoom={false}>
            <TileLayer
                attribution=''
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
        </MapContainer>
    </>
}

*/

const Map = () => {
  return (
    <>
      <ComposableMap>
        <Geographies geography={MapContries}>
          {({ geographies }) =>
            geographies.map((geo) => <Geography key={geo.rsmKey} geography={geo} fill="#EAEAEC" stroke="#D6D6DA" />)
          }
        </Geographies>
      </ComposableMap>
    </>
  );
};

export default Map;
