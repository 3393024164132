import { Box } from '@mui/material';
import _ from 'lodash';
import parse from 'html-react-parser';
import styled from 'styled-components';
import tw from 'twin.macro';
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;
const HTMLContent = styled.div`
  ${tw`mt-2`}
  h2 {
    ${tw`font-semibold py-2 mt-2 text-lg`}
  }
  a {
    ${tw`text-blue-500`}
  }
  ul {
    ${tw`list-disc list-inside`}
  }

  li {
    ${tw`mb-1 ml-4`}
  }
`;
const CommentView = (props: { comment: string }) => {
  const { comment } = props;
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>Comment</Title>
        <HTMLContent>{parse(comment, { trim: true })}</HTMLContent>
      </Box>
    </>
  );
};

export default CommentView;
