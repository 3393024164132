import { MarketData } from "graphql/schema/__generated__/graphql-types";
import tw from "twin.macro";
import NavigationSideBar from "./navigation";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { BriicksFullAdsSections } from "./sections";
import { Box, IconButton, Link, Stack } from "@mui/material";
import ForwardIcon from "@mui/icons-material/Forward";
import ProposedBy from "./proposed-by";
import AdsTitle from "./ads-title";
import PropertySection from "./sections/property";
import InvestmentSection from "./sections/investment";
import FinancialSection from "./sections/financial";
import LegalSection from "./sections/legal";
import ResourceSection from "./sections/resources";

const Container = tw.div`md:flex flex-col md:flex-row md:min-h-screen w-full px-2`;
const CardLetfWraper = tw.div`flex flex-col rounded text-gray-700 w-56 flex-shrink-0`;
const ContentContainer = tw.div`flex flex-col flex-grow pl-4`;

const BriicksFullAds = (props: {
  listing: MarketData;
  isNavigation?: boolean;
}) => {
  const { listing, isNavigation } = props;

  const [visibleArticle, setVisibleArticle] = useState<string[]>([]);
  const navigate = useNavigate();
  const viewPortObserver = (id: string, isVisible: boolean) => {
    if (visibleArticle.includes(id) && !isVisible) {
      setVisibleArticle(visibleArticle.filter((e) => e !== id));
    } else if (!visibleArticle.includes(id) && isVisible) {
      setVisibleArticle([...visibleArticle, id]);
    }
  };
  const goBack = () => {
    //navigate(-1);
    navigate("/market");
  };
  return (
    <>
      <Container>
        <CardLetfWraper>
          <NavigationSideBar
            listing={listing}
            visibleArticle={_.last(visibleArticle) || ""}
            sections={BriicksFullAdsSections}
            isNavigation={isNavigation}
          />
        </CardLetfWraper>
        <ContentContainer>
          <Box>
            <IconButton size="large" color="primary" onClick={goBack}>
              <ForwardIcon sx={{ transform: "rotate(180deg)", fontSize: 40 }} />
            </IconButton>
          </Box>
          <ProposedBy maker={listing.resources?.serviceProvider?.maker} />
          <Stack direction="column" alignItems="end" sx={{ marginTop: 1 }}>
            <Stack>
              <Link href={listing.settings?.originalAdsLink} target="_blank">
                View original Ads
              </Link>
            </Stack>
          </Stack>
          <AdsTitle title={listing.title} />
          <PropertySection
            strategy={listing.investment?.strategy}
            settings={listing.settings}
            property={listing.property}
            viewPortObserver={viewPortObserver}
          />
          <InvestmentSection
            settings={listing.settings}
            investment={listing.investment}
            viewPortObserver={viewPortObserver}
          />
          <FinancialSection
            settings={listing.settings}
            financial={listing.financial}
            viewPortObserver={viewPortObserver}
          />
          <LegalSection
            legal={listing.legal}
            viewPortObserver={viewPortObserver}
          />
          <ResourceSection
            resources={listing.resources}
            viewPortObserver={viewPortObserver}
          />
        </ContentContainer>
      </Container>
    </>
  );
};

export default BriicksFullAds;
