import { Theme } from '@emotion/react';
import { Box, Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { LegalSpv } from 'graphql/schema/__generated__/graphql-types';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import tw from 'twin.macro';
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const Distribution = (props: { spv: LegalSpv | undefined }) => {
  const spv  = props.spv;

  const items: {
    label: string;
    value: number | string;
    titleSx: SxProps<Theme>;
    valueSx: SxProps<Theme>;
  }[] = [
    {
      label: 'Market Offering',
      value: spv?.marketOffering || 'no data',
      titleSx: {},
      valueSx: { ...tw`capitalize text-primary-500` },
    },
  ];
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>Distribution</Title>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: 850, alignSelf: 'center', marginTop: 2 }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  '& .MuiTableCell-root': {
                    padding: 1,
                    //border: 1
                  },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {items.map((e, index: number) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" sx={e.titleSx}>
                        {e.label}
                      </TableCell>
                      <TableCell align="right" sx={e.valueSx}>
                        {_.isNumber(e.value) ? (
                          <NumberFormat
                            value={e.value}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={'text'}
                            thousandSeparator={' '}
                            suffix={' %'}
                            renderText={(value: string) => <span>{value}</span>}
                          />
                        ) : (
                          e.value
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Distribution;
