import { put, takeLatest } from 'redux-saga/effects';
import clientGraphQL from 'graphql/client';
import { PayloadAction } from '@reduxjs/toolkit';
import { MarketDataItemDetailsActions } from 'redux/reducers/market-data-item-details';
import { MarketData, MarketDataItemWithIdDocument, MarketDataItemWithIdQuery, QueryMarketDataItemWithIdArgs } from 'graphql/schema/__generated__/graphql-types';

function* getMarketDataItemDetailsWitIdAsyncRequest(action: PayloadAction<QueryMarketDataItemWithIdArgs>) {
  const args = action.payload;
  try {
    const result: MarketDataItemWithIdQuery = yield clientGraphQL.request(MarketDataItemWithIdDocument, args);
    if (result.marketDataItemWithId.success && result.marketDataItemWithId.data)
      yield put(
        MarketDataItemDetailsActions.getMarketDataItemDetailsWitIdRequestSuccess(
          result.marketDataItemWithId.data as MarketData,
        ),
      );
    else
      yield put(
        MarketDataItemDetailsActions.getMarketDataItemDetailsWitIdRequestFaillure(
          result.marketDataItemWithId.errors?.shift()?.code ?? 'error',
        ),
      );
  } catch (error) {
    yield put(MarketDataItemDetailsActions.getMarketDataItemDetailsWitIdRequestFaillure('error'));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export const MarketDataItemDetailsSaga = [
  //reducer-name/reducer-function
  takeLatest('MarketDataItemDetailsSlice/getMarketDataItemDetailsWitIdRequest', getMarketDataItemDetailsWitIdAsyncRequest),
];
