import { Settings, Financial } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import DetailsView from './details';
import CommentView from './comment';

export const ARTICLE_TITLE = 'Lease details';

const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;
const Divider = tw.hr`
`;

const AcquisitionCost = (props: {
  financial: Financial;
  settings: Settings;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const { financial } = props;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
        <Headline>{ARTICLE_TITLE}</Headline>
        <Divider />
        <DetailsView fianacial={financial} />
        <CommentView comment={financial?.leaseDetails?.comment || 'no data'} />
      </ArticleContainer>
    </>
  );
};

export default AcquisitionCost;
