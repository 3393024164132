import { Theme } from '@emotion/react';
import { Box, Paper, SxProps, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ResourcesServiceProvider } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';

const Details = (props: { provider: Maybe<ResourcesServiceProvider> }) => {
  const { provider } = props;

  const items: {
    label: string;
    value: string;
    titleSx: SxProps<Theme>;
    valueSx: SxProps<Theme>;
  }[] = [
    {
      label: 'BRIICK MAKER',
      value: provider?.maker ?? 'no data',
      titleSx: {},
      valueSx: { ...tw`capitalize text-primary-500` },
    },
    {
      label: 'Property Management',
      value: provider?.propertyManagement ?? 'no data',
      titleSx: {},
      valueSx: { ...tw`capitalize text-primary-500` },
    },
  ];
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: 850, alignSelf: 'center', marginTop: 2 }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  '& .MuiTableCell-root': {
                    padding: 1,
                    //border: 1
                  },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {items.map((e, index: number) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" sx={e.titleSx}>
                        {e.label}
                      </TableCell>
                      <TableCell align="right" sx={e.valueSx}>
                        {e.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Details;
