import { IRoute } from ".";
import HomePage from "../pages/Home";
import MarketPage from "../pages/Market";
import MarketListingsPage from "../pages/Market/listings";
import FaqPage from "../pages/Faq";
import AboutUsPage from "../pages/AboutUs";
import JoinUsPage from "../pages/JoinUs";
import BriicksMakerPage from "../pages/Briicks-Maker";
import JoinBriicksPage from "../pages/JoinBriicks";
import { FAQContent } from "pages/Faq/content";
import { AboutUsContent } from "../pages/AboutUs/content";
import MarketListingDetailsPage from '../pages/Market/listing-details';
import MakerLayout from "components/layouts/maker";
import MakerDashboard from "pages/Maker-Dashboard";
import MakerDeveloper from "pages/Maker-Dashboard/developer";
import MakerAds from "pages/Maker-Dashboard/ads";
import APIDocs from "pages/Maker-Dashboard/api-docs";
import TakerLayout from "../components/layouts/taker";
import TakerSignIn from "pages/taker/signin";
import TakerSignUp from "pages/taker/signup";
import TakerForgotPassword from "pages/taker/forgot-password";
import TermsOfUse from "pages/terms-of-use";
import PrivacyPolicy from "pages/privacy-policy";
//import NewCardPage from 'pages/new-cards';

//import FullAdsPage from 'pages/full-ads';

const RootesData: IRoute[] = [
  {
    path: "/",
    name: "home",
    component: <HomePage />,
  },
  {
    path: "/taker-signin",
    name: "taker signin",
    component: <TakerSignIn />,
    hidden: true,
  },
  {
    path: "/terms-of-use",
    name: "terms of use",
    component: <TermsOfUse />,
    hidden: true,
  },
  {
    path: "/privacy-policy",
    name: "privacy policy",
    component: <PrivacyPolicy />,
    hidden: true,
  },
  {
    path: "/taker-signup",
    name: "taker signup",
    component: <TakerSignUp />,
    hidden: true,
  },
  {
    path: "/taker-forgot-password",
    name: "taker forgot password",
    component: <TakerForgotPassword />,
    hidden: true,
  },
  {
    path: "/market",
    name: "market",
    component: <MarketPage />,
    subRoutes: [
      {
        path: "",
        component: <MarketListingsPage />,
      },
      {
        path: "ads-details/:id",
        component: <MarketListingDetailsPage />,
      },
    ],
  },
  {
    path: "/briicks-maker",
    name: "briicks maker",
    component: <BriicksMakerPage />,
  },
  {
    path: "/faq",
    name: "faq",
    component: <FaqPage content={FAQContent} />,
  },
  /* {
    path: '/full-ads',
    name: 'fullAds',
    hidden: true,
    component: <FullAdsPage />,
  }, */
  {
    path: "/about-us",
    name: "about us",
    component: <AboutUsPage content={AboutUsContent} />,
  },
  /*{
    path: '/new-card',
    name: 'new card',
    component: <NewCardPage />,
  },
  {
            path: "/sign-in",
            name: "sign in",
            component: <h1>hello</h1>
        },*/
  {
    path: "/join-us",
    hidden: true,
    component: <JoinUsPage />,
  },
  {
    path: "/join-briicks",
    hidden: true,
    component: <JoinBriicksPage />,
  },
  {
    path: "/taker",
    hidden: true,
    component: <TakerLayout />,
  },
  {
    path: "/maker",
    hidden: true,
    component: <MakerLayout />,
    subRoutes: [
      {
        path: "",
        component: <MakerDashboard />,
      },
      {
        path: "ads",
        component: <MakerAds />,
      },
      {
        path: "developer",
        component: <MakerDeveloper />,
      },
      {
        path: "api-docs",
        component: <APIDocs />,
      },
    ],
  },
  {
    path: "/*",
    hidden: true,
    component: <h1>404</h1>,
  },
];

export default RootesData;
