import NumberFormat from 'react-number-format';
import tw from 'twin.macro';

const Div = tw.div`
bg-white overflow-hidden flex items-center justify-center rounded-md ml-1 mb-1 p-1 px-2 
text-orange-500 whitespace-nowrap truncate
`;

//tw`absolute w-24 rounded-md text-center mx-2 my-2 px-2 py-1 text-white uppercase top-0 left-0`
export const TotalPropertyValue = (props: { value: number }) => {
  return (
    <Div {...props}>
      <NumberFormat
        value={props.value}
        decimalScale={2}
        fixedDecimalScale
        displayType={'text'}
        thousandSeparator={' '}
        prefix={'$ '}
        renderText={(value: string) => <span>{value}</span>}
      />
    </Div>
  );
};

export default TotalPropertyValue;
