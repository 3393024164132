import { Divider } from "@mui/material";
import {
  Maybe,
  PropertyLocation,
} from "graphql/schema/__generated__/graphql-types";
import _ from "lodash";
import tw from "twin.macro";
import { Tooltip } from "@mui/material";
const Container = tw.div`
col-span-3 pr-1
`;
const Heading2 = tw.h2`
font-medium text-base text-secondary-900 mr-1
`;
const Val = tw.div`
text-sm text-blue-500 cursor-pointer
`;

const AddressView = (props: { location?: Maybe<PropertyLocation> }) => {
  const { location } = props;

  let addressLine1 = ``;
  const number = location?.number ?? "";
  const street = location?.street ?? "";
  if (!_.isEmpty(number) && number.toLowerCase() !== "no data")
    addressLine1 = `${number}`;
  if (!_.isEmpty(street) && street.toLowerCase() !== "no data")
    addressLine1 = `${addressLine1} ${street}`;

  let addressLine2 = ``;
  const city = location?.city ?? "";
  const state = location?.state ?? "";
  const zipCode = location?.zipCode ?? "";
  if (!_.isEmpty(city) && city.toLowerCase() !== "no data")
    addressLine2 = `${city},`;
  if (!_.isEmpty(state) && state.toLowerCase() !== "no data")
    addressLine2 = `${addressLine2} ${state}`;
  if (!_.isEmpty(zipCode) && zipCode.toLowerCase() !== "no data")
    addressLine2 = `${addressLine2} ${zipCode}`;

  const handleClick = () => {
    navigator.clipboard.writeText(`${addressLine1} ${addressLine2} ${location?.country ?? ""}`.trim());
  };
  return (
    <Container>
      <Heading2>Address</Heading2>
      <Divider variant={undefined} css={tw`bg-secondary-900 mr-2!`} />
      <Tooltip title="Copy" placement="top">
        <div onClick={handleClick}>
          <Val>{addressLine1}</Val>
          <Val>{addressLine2}</Val>
          <Val>{location?.country ?? "no data"}</Val>
        </div>
      </Tooltip>
    </Container>
  );
};

export default AddressView;
