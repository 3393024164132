import { useState, useRef, useEffect } from "react";
import Header from "components/header";
import Footer from "components/footer";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import tw from "twin.macro";
import _ from "lodash";
import ErrorAlert from "components/alert/error";
import { ReactComponent as ShowFilterIcon } from "feather-icons/dist/icons/filter.svg";
import { ReactComponent as CloseFilterIcon } from "feather-icons/dist/icons/x.svg";
import ListingFilters from "./filters";
import styled from "styled-components";
import { useSelector } from "redux/store";
import { ApiInputType } from "./types";
import {
  MarketData,
  MarketDataFilters,
  MarketDataSortBy,
} from "graphql/schema/__generated__/graphql-types";
import GlobalFiltersSorts from "./global-filters-sorts";
import {
  MarketDataItemsActions,
  initialState,
} from "redux/reducers/market-data";
import { useDispatch } from "react-redux";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import AssetSkeleton from "components/cards/asset-skeleton";
import { SecondaryButton as SecondaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as ArrowLeftIcon } from "feather-icons/dist/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "feather-icons/dist/icons/arrow-right.svg";
import BriicksShortAds from "components/cards/short-ads";

const Container = tw(ContainerBase)`-mx-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Wraper = tw.div`md:flex flex-col md:flex-row md:min-h-screen w-full`;
const SideBareWraper = tw.div`flex flex-col w-full md:w-52 text-gray-700 bg-white flex-shrink-0`;
const FilterToggle = tw.div`flex-shrink-0 px-8 p-4 lg:py-0 flex flex-row items-center justify-between`;
const FilterHead = tw.h1`text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg`;
const FilterButton = tw.button`rounded-lg md:hidden rounded-lg focus:outline-none focus:shadow-outline`;
const MobileFilterContent = tw.div`lg:hidden flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto`;
const DesktopFilterContent = styled.div`
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
  ${tw`hidden lg:flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto`}
`;
const ListingContainer = tw.div`flex flex-col items-center flex-grow`;
const NoResultContainer = tw.div`flex w-full h-10 py-8 justify-center items-center`;
const NoResultHead = tw.h5`ml-2 text-2xl font-black tracking-wider`;
const PaginationContainer = tw.div`flex flex-col items-center mt-8`;
const Grid = tw.div`grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 items-center relative`;
const PagesLabel = tw.span`text-sm text-gray-500`;
const PagesNumber = tw.span`font-semibold text-gray-700`;
const PaginationButtonsContainer = tw.div`inline-flex mt-2`;
const SecondaryButton = styled(SecondaryButtonBase)`
  ${tw`inline-flex items-center py-2! px-4! text-sm font-medium first:mr-4`}
  svg {
    ${tw`h-5`}
  }
`;

const MarketPage = () => {
  const dispatche = useDispatch();
  let containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const globalFilter = useRef<any>(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const marketData = useSelector((state) => state.marketDataItems);
  const [apiInput, setApiInput] = useState<ApiInputType>({
    page: 1,
    limit: 9,
    filters: marketData.filters,
    sortBy: marketData.sortBy,
  });

  const handleResetFilters = () => {
    setApiInput({
      ...apiInput,
      sortBy: initialState.sortBy,
      filters: initialState.filters,
    });

    if (globalFilter.current && globalFilter.current.resetSort) {
      globalFilter.current.resetSort();
    }
  };
  const onChangeFilters = (filters: MarketDataFilters) => {
    if (!_.isEqual(filters, apiInput.filters)) {
      const { globalSearch = "" } = apiInput.filters;
      setApiInput({
        ...apiInput,
        filters: { ...filters, globalSearch },
        page: 1,
      });
    }
  };

  const handleOnGlobalSearchChange = (query: string) => {
    const { globalSearch = "", ...rest } = apiInput.filters;
    if (!_.isEqual(query, globalSearch)) {
      setApiInput({
        ...apiInput,
        filters: { ...rest, globalSearch: query },
        page: 1,
      });
    }
  };

  const handleOnSortChange = (sortBy: MarketDataSortBy) => {
    if (!_.isEqual(sortBy, apiInput.sortBy)) {
      setApiInput({ ...apiInput, sortBy, page: 1 });
    }
  };

  const handleChangePage = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    const limit = event.target.value as number;
    setApiInput({ ...apiInput, limit, page: 1 });
    window.scrollTo({
      behavior: "smooth",
      top: containerRef?.current?.offsetTop,
    });
  };

  const setCurrentPage = (page: number) => {
    setApiInput({ ...apiInput, page });
    window.scrollTo({
      behavior: "smooth",
      top: containerRef?.current?.offsetTop,
    });
  };

  const renderSkeleton = () => {
    return (
      <Grid css={tw`px-4 sm:px-0`}>
        {[1, 2, 3].map((e) => (
          <div key={e}>
            <AssetSkeleton />
          </div>
        ))}
      </Grid>
    );
  };

  const totalDocs = marketData.response.totalDocs;
  const startIndex =
    ((marketData.response.page || 1) - 1) * marketData.response.limit + 1;

  const lastIndex = marketData.response.hasNextPage
    ? marketData.response.limit * (marketData.response.page || 1)
    : marketData.response.totalDocs;

  const renderResults = () => {
    return (
      <>
        <Grid css={tw`px-4 sm:px-0`}>
          {marketData.response.docs.map((data: MarketData, index: number) => {
            return (
              <div key={index}>
                {/* <AssetCard key={index} listing={listing} /> */}
                <BriicksShortAds key={index} ads={data} />
              </div>
            );
          })}
        </Grid>
        {marketData.response.totalDocs > 0 && (
          <Box css={tw`mt-8 p-2 flex w-full items-center justify-center`}>
            <Pagination
              count={marketData.response.totalPages}
              page={apiInput.page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
            />
            <FormControl
              variant="outlined"
              size="small"
              sx={{ width: 150, marginLeft: 4 }}
            >
              <InputLabel id="rows-per-page-label">Ads per page</InputLabel>
              <Select
                labelId="rows-per-page-label"
                id="rows-per-page-select"
                label="Ads per page"
                value={apiInput.limit}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={18}>18</MenuItem>
                <MenuItem value={27}>27</MenuItem>
                <MenuItem value={54}>54</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}

        {false && marketData.response.totalDocs > 0 && (
          <PaginationContainer>
            <PagesLabel>
              Showing <PagesNumber>{startIndex}</PagesNumber> to{" "}
              <PagesNumber>{lastIndex}</PagesNumber> of{" "}
              <PagesNumber>{totalDocs}</PagesNumber> Offers
            </PagesLabel>
            <PaginationButtonsContainer>
              <SecondaryButton
                onClick={() => {
                  setCurrentPage(apiInput.page - 1);
                }}
                disabled={!!!marketData.response.hasPrevPage}
              >
                <ArrowLeftIcon />
                Prev
              </SecondaryButton>
              <SecondaryButton
                onClick={() => {
                  setCurrentPage(apiInput.page + 1);
                }}
                disabled={!!!marketData.response.hasNextPage}
              >
                Next
                <ArrowRightIcon />
              </SecondaryButton>
            </PaginationButtonsContainer>
          </PaginationContainer>
        )}
      </>
    );
  };

  ///////Effect
  useEffect(() => {
    // Set up interval
    
    const interval = setInterval(() => {
      dispatche(
        MarketDataItemsActions.refreshMarketDataItemsRequest({
          page: marketData.response.page || 1,
          limit: marketData.response.limit,
          filters: marketData.filters,
          sortBy: marketData.sortBy,
        }) 
      );
    }, 10000); // Fetch every 20 seconds

    // Return a function to be run when the component is unmounted
    return () => {
      clearInterval(interval);
    };
  }, [marketData.response]);

  useEffect(() => {
    dispatche(
      MarketDataItemsActions.getMarketDataItemsRequest({
        page: apiInput.page,
        limit: apiInput.limit,
        filters: apiInput.filters,
        sortBy: apiInput.sortBy,
      })
    );
  }, [apiInput, dispatche]);

  return (
    <>
      <>
        <div ref={containerRef} />
        <Header />
        <Container>
          <Content>
            {!_.isEmpty(marketData.error) && (
              <ErrorAlert message="Oops looks like something went wrong." />
            )}
            <Wraper>
              <SideBareWraper>
                <FilterToggle>
                  <FilterHead>FILTERS</FilterHead>
                  <FilterButton
                    onClick={() => setFilterVisible(!filterVisible)}
                  >
                    {filterVisible ? <CloseFilterIcon /> : <ShowFilterIcon />}
                  </FilterButton>
                </FilterToggle>
                <MobileFilterContent hidden={!filterVisible}>
                  <ListingFilters
                    onChangeFilters={onChangeFilters}
                    onResetFilters={handleResetFilters}
                  />
                </MobileFilterContent>
                <DesktopFilterContent>
                  <ListingFilters
                    onChangeFilters={onChangeFilters}
                    onResetFilters={handleResetFilters}
                  />
                </DesktopFilterContent>
              </SideBareWraper>
              <ListingContainer>
                <GlobalFiltersSorts
                  ref={globalFilter}
                  apiInput={apiInput}
                  onGlobalSearchChange={handleOnGlobalSearchChange}
                  onSortChange={handleOnSortChange}
                />

                {!marketData.isLoading &&
                  marketData.response.totalDocs === 0 && (
                    <NoResultContainer>
                      <NoResultHead>No offers found.</NoResultHead>
                    </NoResultContainer>
                  )}
                {marketData.isLoading ? renderSkeleton() : renderResults()}
              </ListingContainer>
            </Wraper>
          </Content>
        </Container>
        <Footer />
      </>
    </>
  );
};

export default MarketPage;
