import { Box, Dialog, DialogContent, IconButton } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import _ from 'lodash';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function srcset(image: string, size: number, rows = 1, cols = 1) {
  const c = Math.floor(Math.random() * 10);
  const src = `${image}?tr:w-${size * cols + c},h-${size * rows + c}`;
  return {
    src,
  };
  /*return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };*/
}

interface ITile {
  img: string;
  title: string;
  rows: number;
  cols: number;
}

function tiles(images: string[], inverse: boolean): ITile[] {
  if (images.length === 1)
    return [
      {
        img: images[0],
        title: '',
        rows: 2,
        cols: 4,
      },
    ];
  else if (images.length === 2)
    return [
      {
        img: images[0],
        title: '',
        rows: 2,
        cols: 2,
      },
      {
        img: images[1],
        title: '',
        rows: 2,
        cols: 2,
      },
    ];
  else if (images.length === 3)
    return [
      {
        img: images[0],
        title: '',
        rows: 2,
        cols: inverse ? 4 : 2,
      },
      {
        img: images[1],
        title: '',
        rows: 2,
        cols: 2,
      },
      {
        img: images[2],
        title: '',
        rows: 2,
        cols: inverse ? 2 : 4,
      },
    ];
  else if (images.length === 4)
    return [
      {
        img: images[0],
        title: '',
        rows: inverse ? 2 : 3,
        cols: 2,
      },
      {
        img: images[1],
        title: '',
        rows: inverse ? 3 : 2,
        cols: 2,
      },
      {
        img: images[2],
        title: '',
        rows: 1,
        cols: 1,
      },
      {
        img: images[3],
        title: '',
        rows: 1,
        cols: 1,
      },
    ];
  else if (images.length >= 5)
    return [
      {
        img: images[0],
        title: '',
        rows: 2,
        cols: 4,
      },
      {
        img: images[1],
        title: '',
        rows: inverse ? 1 : 2,
        cols: 2,
      },
      {
        img: images[2],
        title: '',
        rows: inverse ? 2 : 1,
        cols: 2,
      },
      {
        img: images[3],
        title: '',
        rows: 1,
        cols: 1,
      },
      {
        img: images[4],
        title: '',
        rows: 1,
        cols: 1,
      },
    ];
  else return [];
}

const QuiltedImageList = (props: { images: string[] } & any) => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const imagesItems = _.chain(props.images)
    .chunk(5)
    .reduce<ITile[]>((acc, o, index) => {
      return [...acc, ...tiles(o, index % 2 === 0)];
    }, [])
    .value();

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (imgIndex: number) => {
    setSelectedImage(imgIndex);
    setOpen(true);
  };

  return (
    <>
      <ImageList
        //sx={{ width: 500, height: 450 }}
        variant="quilted"
        cols={4}
        rowHeight={200}
      >
        {imagesItems.map((item: ITile, index: number) => (
          <ImageListItem
            sx={{ cursor: 'pointer' }}
            key={item.img}
            cols={item.cols || 1}
            rows={item.rows || 1}
            onClick={() => handleClickOpen(index)}
          >
            <img {...srcset(item.img, 200, item.rows, item.cols)} alt={item.title} loading="lazy" />
          </ImageListItem>
        ))}
      </ImageList>

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: '90vh', // 80% of viewport height
            maxWidth: '60vw', // 80% of viewport width
            overflow: 'auto',
          },
        }}
      >
        <DialogContent
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflowX: 'hidden', padding: 8 }}
        >
          <Carousel
            showThumbs={false}
            selectedItem={selectedImage}
            showStatus={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <IconButton
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: 'absolute',
                    left: 15,
                    top: 'calc(50% - 15px)',
                    zIndex: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <IconButton
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    position: 'absolute',
                    right: 15,
                    top: 'calc(50% - 15px)',
                    zIndex: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                  }}
                >
                  <ArrowForwardIcon />
                </IconButton>
              )
            }
          >
            {props.images.map((img: string, index: number) => (
              <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={img}
                  style={{ height: 'auto', maxWidth: '100%', maxHeight: '90vh', objectFit: 'contain' }}
                  alt=""
                />
              </div>
            ))}
          </Carousel>

          <Box sx={{ position: 'absolute', top: 15, right: 15 }}>
            <IconButton
              size="small"
              onClick={handleClose}
              aria-label="close"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                color: '#ccc',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QuiltedImageList;
