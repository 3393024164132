import { combineReducers } from 'redux';
import DynamicFilters from 'redux/reducers/dynamic-filters';
import MarketDataItems from 'redux/reducers/market-data';
import MarketDataItemDetails from 'redux/reducers/market-data-item-details';

export default combineReducers({
  dynamicFilters: DynamicFilters.reducer,
  marketDataItems: MarketDataItems.reducer,
  marketDataItemDetails: MarketDataItemDetails.reducer,
});
