import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  GenericPaginationMarketData,
  MarketDataSortBy,
  Market_Data_Items_Sort_By_Values,
  QueryMarketDataItemsArgs,
} from "graphql/schema/__generated__/graphql-types";

export interface MarketDataState {
  filters: any;
  sortBy: MarketDataSortBy;
  response: GenericPaginationMarketData;
  isLoading: boolean;
  error: string;
}

export const initialState: MarketDataState = {
  filters: {
    globalSearch: "",
    status: [],
    shortCode: "",
    countries: [],
    makers: [],
    metrics: [],
  },
  sortBy: {
    by: Market_Data_Items_Sort_By_Values.DateDes,
    withNoData: true,
  },
  response: {
    page: 1,
    limit: 9,
    docs: [],
    totalDocs: 0,
    hasNextPage: false,
    hasPrevPage: false,
    nextPage: 0,
    offset: 0,
    pagingCounter: 0,
    prevPage: 0,
    totalPages: 0,
  },
  isLoading: false,
  error: "",
};

export const MarketDataItemsSlice = createSlice({
  name: "MarketDataItemsSlice",
  initialState,
  reducers: {
    refreshMarketDataItemsRequest: (
      _state,
      _action: PayloadAction<QueryMarketDataItemsArgs>
    ) => {},
    getMarketDataItemsRequest: (
      state,
      action: PayloadAction<QueryMarketDataItemsArgs>
    ) => {
      state.isLoading = true;
      state.filters = action.payload.filters!;
      state.sortBy = action.payload.sortBy!;
      state.error = "";
    },
    getMarketDataItemsRequestSuccess: (
      state,
      action: PayloadAction<GenericPaginationMarketData>
    ) => {
      state.isLoading = false;
      state.response = action.payload;
    },
    getMarketDataItemsRequestFaillure: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const MarketDataItemsActions = MarketDataItemsSlice.actions;
export default MarketDataItemsSlice;
