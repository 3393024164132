import { IFullAdsSection } from 'components/common/types';
import { Legal } from 'graphql/schema/__generated__/graphql-types';
import { ThemeProvider } from '@mui/material';
import SectionContainer from 'components/article/section-container';
import { theme } from '../../theme';
import SPV, { ARTICLE_TITLE as SPVName } from './spv';
import LedgerDLT, { ARTICLE_TITLE as LedgerDLTName } from './ledger-dlt';
import Restrictions, { ARTICLE_TITLE as RestrictionsName } from './restriction';
import { Maybe } from 'graphql/jsutils/Maybe';

export const elements: IFullAdsSection[] = [
  {
    name: SPVName,
    element: SPV,
  },
  {
    name: LedgerDLTName,
    element: LedgerDLT,
  },
  {
    name: RestrictionsName,
    element: Restrictions,
  },
];

const LegalSection = (props: {
  legal: Maybe<Legal>;
  viewPortObserver: (articleName: string, isVisible: boolean) => void;
}) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <SectionContainer>
          {elements.map((e, index) => {
            const V = e.element;
            return <V legal={props.legal} id={e.name} key={index} viewPortObserver={props.viewPortObserver} />;
          })}
        </SectionContainer>
      </ThemeProvider>
    </>
  );
};

export default LegalSection;
