import Header from 'components/header';
import Footer from 'components/footer';
//import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import ContactUsForm from 'components/form/contact-us';
import SubscribeNewsLetter from 'components/form/subscribe-news-letter';
import tw from 'twin.macro';
import SquiibzLogo from 'images/squiibz.png';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)<{ textOnLeft: boolean }>((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div<{ imageSrc: string }>((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading,
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-primary-700`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export interface IAboutUsContent {
  SubTitle: string;
  Title: ReactElement;
  Description: string;
  Statistics: {
    key: string;
    value: string;
  }[];
  CTA: {
    text: string;
    link: string;
  };
}

const AboutUsPage = (props: { content: IAboutUsContent }) => {
  return (
    <>
      <>
        <Header />
        <Container>
          <TwoColumn>
            <ImageColumn>
              <Image imageSrc={SquiibzLogo} />
            </ImageColumn>

            <TextColumn textOnLeft={false}>
              <TextContent>
                {props.content.SubTitle && <Subheading>{props.content.SubTitle}</Subheading>}
                <Heading>{props.content.Title}</Heading>
                <Description>{props.content.Description}</Description>
                <Statistics>
                  {props.content.Statistics.map((statistic, index) => (
                    <Statistic key={index}>
                      <Value>{statistic.value}</Value>
                      <Key>{statistic.key}</Key>
                    </Statistic>
                  ))}
                </Statistics>
                <PrimaryButton as="a" href={props.content.CTA.link}>
                  {props.content.CTA.text}
                </PrimaryButton>
              </TextContent>
            </TextColumn>
          </TwoColumn>
        </Container>
        <ContactUsForm />
        <SubscribeNewsLetter />
        <Footer />
      </>
    </>
  );
};

export default AboutUsPage;
