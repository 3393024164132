import { Settings, Investment } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import { Typography } from '@mui/material';
import CalendarView from './calendar-view';

export const ARTICLE_TITLE = 'Calendar';

const Headline = tw.h1`
text-primary-500 font-bold text-3xl
flex flex-row justify-between items-end 
`;
const Divider = tw.hr`
`;

const Calendar = (props: {
  investment: Investment;
  settings: Settings;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const { investment } = props;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
        <Headline>
          {ARTICLE_TITLE}
          <Typography sx={tw`text-orange-500 font-bold text-xl`}>TimeZone: UTC</Typography>
        </Headline>
        <Divider />

        <CalendarView investment={investment} />
      </ArticleContainer>
    </>
  );
};

export default Calendar;
