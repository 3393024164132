import { useMemo, useEffect, useState } from 'react';

interface IntersectionObserverEntry {
  boundingClientRect: {
    height: number;
  };
  isIntersecting: boolean;
}

export function useIsInViewport(
  ref: React.RefObject<HTMLElement>,
  threshold: number = 0.1,
  minHeight?: number,
): boolean {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  const observer = useMemo(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: threshold,
    };

    return new IntersectionObserver(([entry]: IntersectionObserverEntry[]) => {
      if (minHeight && entry.boundingClientRect.height < minHeight) {
        setIsIntersecting(true);
      } else {
        setIsIntersecting(entry.isIntersecting);
      }
      //console.log('isIntersecting:', entry.isIntersecting);
    }, options);
  }, [threshold, minHeight]);

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
