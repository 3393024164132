import Header from 'components/header';
import Footer from 'components/footer';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import tw from 'twin.macro';
import { Box, Button, Checkbox, FormControlLabel, Link, Divider as DividerUI, TextField } from '@mui/material';

const Container = tw(ContainerBase)`-mx-8 px-4 `;
const Content = tw.div`max-w-screen-sm mx-auto py-20 lg:py-24`;
const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;
const Divider = tw.hr`
`;

const SignIn = () => {
  const handleSubmit = () => {};
  return (
    <>
      <Header />
      <Container>
        <Content>
          <Headline>Sign in</Headline>
          <Divider />
          <Box sx={tw`flex justify-center`}>
            <Box component="form" width={400} onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="off"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="off"
              />

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
                sx={{ display: 'block' }}
              />

              <Link href="taker-forgot-password" sx={{ display: 'block' }}>
                Forgot your password?
              </Link>

              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 2 }}>
                <Button
                  href="/taker"
                  color="success"
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ width: 200 }}
                >
                  Sign In
                </Button>
              </Box>

              <DividerUI>OR</DividerUI>

              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 2 }}>
                <Button href="taker-signup" size="large" variant="contained" sx={{ width: 200 }}>
                  Sign Up
                </Button>
              </Box>
            </Box>
          </Box>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default SignIn;
