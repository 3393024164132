import { put, takeLatest } from 'redux-saga/effects';
import clientGraphQL from 'graphql/client';
import { DynamicFilterActions } from 'redux/reducers/dynamic-filters';
import { MarketDataItemsFiltersDocument, MarketDataItemsFiltersQuery } from 'graphql/schema/__generated__/graphql-types';

function* getMarketDataFiltersAsyncRequest() {
  try {
    const result: MarketDataItemsFiltersQuery = yield clientGraphQL.request(MarketDataItemsFiltersDocument);
    if (result.marketDataItemsFilters.success && result.marketDataItemsFilters.data)
      yield put(DynamicFilterActions.getMarketDataFiltersRequestSuccess(result.marketDataItemsFilters.data));
    else
      yield put(
        DynamicFilterActions.getMarketDataFiltersRequestFaillure(
          result.marketDataItemsFilters.errors?.shift()?.message || '',
        ),
      );
  } catch (error) {
    yield put(DynamicFilterActions.getMarketDataFiltersRequestFaillure('error'));
  }
}

/* ------------- Connect Types To Sagas ------------- */
export const DynamicFiltersSaga = [
  //reducer-name/reducer-function
  takeLatest('DynamicFilterSlice/getMarketDataFiltersRequest', getMarketDataFiltersAsyncRequest),
];
