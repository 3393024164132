import Header from 'components/header';
import Footer from 'components/footer';

const JoinBriicksPage = () => {
  return (
    <>
      <Header />
      <Footer />
    </>
  );
};

export default JoinBriicksPage;
