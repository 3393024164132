import { useState } from 'react';
import AccessView from './access-view';
import ChangeEmail from './change-email';
import ChangePassword from './change-password';

export const Title = 'Access';

const Access = () => {
  const [action, setAction] = useState<'change-email' | 'change-password' | null>(null);

  if (action === 'change-email') return <ChangeEmail />;
  else if (action === 'change-password') return <ChangePassword />
  else return <AccessView setAction={setAction} />;
};

export default Access;
