import { put, takeLatest } from 'redux-saga/effects';
import clientGraphQL from 'graphql/client';
import { PayloadAction } from '@reduxjs/toolkit';
import { GenericPaginationMarketData, MarketDataItemsDocument, MarketDataItemsQuery, QueryMarketDataItemsArgs } from 'graphql/schema/__generated__/graphql-types';
import { MarketDataItemsActions } from 'redux/reducers/market-data';

function* getMarketDataItemsAsyncRequest(action: PayloadAction<QueryMarketDataItemsArgs>) {
  const args = action.payload;
  try {
    const result: MarketDataItemsQuery = yield clientGraphQL.request(MarketDataItemsDocument, args);
    if (result.marketDataItems.success && result.marketDataItems.data)
      yield put(MarketDataItemsActions.getMarketDataItemsRequestSuccess(result.marketDataItems.data as GenericPaginationMarketData));
    else
      yield put(
        MarketDataItemsActions.getMarketDataItemsRequestFaillure(result.marketDataItems.errors?.shift()?.message || 'error'),
      );
  } catch (error) {
    yield put(MarketDataItemsActions.getMarketDataItemsRequestFaillure('error'));
  }
}

function* refreshMarketDataItemsAsyncRequest(action: PayloadAction<QueryMarketDataItemsArgs>) {
  const args = action.payload;
  try {
    const result: MarketDataItemsQuery = yield clientGraphQL.request(MarketDataItemsDocument, args);
    if (result.marketDataItems.success && result.marketDataItems.data)
      yield put(
        MarketDataItemsActions.getMarketDataItemsRequestSuccess(result.marketDataItems.data as GenericPaginationMarketData),
      );
  } catch (error) {}
}

/* ------------- Connect Types To Sagas ------------- */
export const MarketDataSaga = [
  //reducer-name/reducer-function
  takeLatest('MarketDataItemsSlice/getMarketDataItemsRequest', getMarketDataItemsAsyncRequest),
  takeLatest('MarketDataItemsSlice/refreshMarketDataItemsRequest', refreshMarketDataItemsAsyncRequest),
];