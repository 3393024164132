import { useState } from 'react';
import {
  Drawer as MuiDrawer,
  IconButton,
  Divider,
  MenuList,
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import {
  ArrowCircleRight as ArrowCircleRightIcon,
  ArrowCircleLeft as ArrowCircleLeftIcon,
  ApiOutlined as ApiOutlinedIcon,
  FormatListBulleted as FormatListBulletedIcon,
  Dashboard as DashboardIcon,
  Logout as LogoutIcon,
  LibraryBooks as LibraryBooksIcon,
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

const drawerWidth: number = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const MakerSideBar = () => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const location = useLocation();

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {open && <Typography variant="h5">Briicks Maker</Typography>}
          <IconButton onClick={handleDrawerOpen}>
            {open ? <ArrowCircleLeftIcon /> : <ArrowCircleRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box flex="auto">
          <MenuList>
            <MenuItem component="a" href="/maker" selected={location.pathname === '/maker'}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Dashboard" />}
            </MenuItem>

            <MenuItem component="a" href="/maker/ads" selected={location.pathname === '/maker/ads'}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Ads" />}
            </MenuItem>

            <MenuItem component="a" href="/maker/developer" selected={location.pathname === '/maker/developer'}>
              <ListItemIcon>
                <ApiOutlinedIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Developer" />}
            </MenuItem>
            {
              //    /maker/api-docs
            }
            <MenuItem component="a" href="/docs" selected={location.pathname === '/maker/api-docs'}>
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              {open && <ListItemText primary="API Docs" />}
            </MenuItem>
          </MenuList>
        </Box>
        <Divider />
        <Box p={1}>
          <MenuItem>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            {open && <ListItemText primary="Logout" />}
          </MenuItem>
        </Box>
      </Drawer>
    </>
  );
};

export default MakerSideBar;
