import { Box, List, ListItem, Typography } from '@mui/material';
import { Investment } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const InvestmentType = (props: { investment: Investment }) => {
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>
          Type: <Title css={tw`inline text-green-500 uppercase`}>{props.investment?.type || 'no data'}</Title>
        </Title>

        <Typography variant="body1" gutterBottom sx={{ marginTop: 1 }}>
          Rental investment involves purchasing a property with the intention of leasing it to tenants, thereby
          generating income.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Key factors to optimize profitability in rental investment include the quality and location of the property,
          stability of rental income, market conditions, and effective management.
        </Typography>
        <Typography variant="body1" gutterBottom>
          While rental investment primarily focuses on rental yield, it also offers potential for capital gains during
          an opportune resale.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Therefore, you have the flexibility to retain your Briicks, sell them individually anytime at your discretion,
          or collectively when a predefined duration is reached or if the property is sold by a majority vote.
        </Typography>

        <Typography variant="body1" sx={{ marginTop: 2 }}>
          <strong>Advantages:</strong>
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              display: 'list-item',
              padding: 0,
            },
          }}
        >
          <ListItem>Regular rental income</ListItem>
          <ListItem>Potential capital appreciation upon resale</ListItem>
          <ListItem>Tax benefits</ListItem>
          <ListItem>Diversification of investment portfolio</ListItem>
        </List>

        <Typography variant="body1" sx={{ marginTop: 2 }}>
          <strong>Disadvantages:</strong>
        </Typography>

        <List
          sx={{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              display: 'list-item',
              padding: 0,
            },
          }}
        >
          <ListItem>Property and tenant management</ListItem>
          <ListItem>Maintenance and repair costs</ListItem>
          <ListItem>Risk of rental vacancies</ListItem>
          <ListItem>Fluctuation in real estate prices</ListItem>
        </List>
      </Box>
    </>
  );
};

export default InvestmentType;
