import Header from "components/header";
import Footer from "components/footer";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useSelector } from "redux/store";
import { ReactComponent as LoaderIcon } from "feather-icons/dist/icons/loader.svg";
import _ from "lodash";
import ErrorAlert from "components/alert/error";
import BriicksFullAds from "components/cards/full-ads";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { MarketDataItemDetailsActions } from "redux/reducers/market-data-item-details";

const Container = tw(ContainerBase)`-mx-8 px-4 `;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const LoadingContainer = styled(motion.div)`
  ${tw`flex w-full h-10 py-8 justify-center items-center`}
  svg {
    ${tw`h-10 w-10`}
  }
`;

const ListingDetails = () => {
  const dispatch = useDispatch();

  const marketDataItemDetails = useSelector(
    (state) => state.marketDataItemDetails
  );

  let error = ``;
  if (!_.isEmpty(marketDataItemDetails.error.trim())) {
    error =
      marketDataItemDetails.error.trim() === "ITEM_NOT_FOUND_ERROR"
        ? "Ads not found"
        : "Oops looks like something went wrong.";
  }

  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    if (id)
      dispatch(
        MarketDataItemDetailsActions.getMarketDataItemDetailsWitIdRequest({
          _id: id,
        })
      );
  }, [id, dispatch]);

  return (
    <>
      <Header />
      <Container>
        <Content>
          {marketDataItemDetails.isLoading && (
            <LoadingContainer
              animate={{ rotate: 360 }}
              transition={{
                loop: Infinity,
                ease: "linear",
                duration: 1.5,
              }}
            >
              <LoaderIcon />
            </LoadingContainer>
          )}

          {!_.isEmpty(error) && <ErrorAlert message={error} />}

          {marketDataItemDetails.marketDataItem && (
            <BriicksFullAds
              listing={marketDataItemDetails.marketDataItem}
              isNavigation={true}
            />
          )}
        </Content>
      </Container>
      <Footer />
    </>
  );
};
export default ListingDetails;
