import { Box, Typography } from "@mui/material";
import { Investment } from "graphql/schema/__generated__/graphql-types";
import tw from "twin.macro";
import InfoIcon from "@mui/icons-material/Info";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import moment from "moment";

const CalendarView = (props: { investment: Investment }) => {
  const calendar = props.investment?.calendar || undefined;
  //const Dates = [...calendar]; //.reverse();
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Box
          flexDirection="column"
          sx={{
            display: "flex",
            alignItems: calendar === undefined ? 'start' : "center",
            justifyContent: "center",
          }}
        >
          {calendar === undefined ? (
            "no data"
          ) : (
            <>
              <Timeline sx={{ minWidth: 650 }}>
                {[...calendar].map((el, index: number) => (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent
                      color={"#38b2ac"}
                      sx={tw`capitalize`}
                    >
                      {el.title}
                    </TimelineOppositeContent>

                    <TimelineSeparator>
                      <TimelineDot
                        variant="outlined" //sx={{ borderColor: '#38b2ac' }}
                      />
                      {index < calendar.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>

                    <TimelineContent>
                      {moment.utc(el.date).format("LL [-] HH[h]mm")}
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
              <Typography sx={tw`text-blue-500 text-base mt-2`}>
                <InfoIcon /> Sign In to get personalize TimeZone
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CalendarView;
