import Logo from 'images/briiks-market.svg';

const Config = {
  Logo: {
    imgSrc: Logo,
    text: 'Briicks Market',
  },
};

export default Config;
