import { ReactNode, useState } from "react";
import {
  Box,
  Button,
  ListItem,
  ListItemIcon,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import tw from "twin.macro";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const Headline = tw.h1`
  text-green-500 font-semibold text-2xl
`;

type PasswordRuleProps = {
  isValid: boolean;
  children: ReactNode;
};

const PasswordRule: React.FC<PasswordRuleProps> = ({ isValid, children }) => (
  <ListItem>
    <ListItemIcon sx={{ color: isValid ? "green" : "red", minWidth: 25 }}>
      <FiberManualRecordIcon fontSize="small" />
    </ListItemIcon>
    <Typography style={{ color: isValid ? "green" : "red" }}>
      {children}
    </Typography>
  </ListItem>
);

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isPasswordChecked, setPasswordChecked] = useState(false);
  const [isPasswordChanged, setPasswordChanged] = useState(false);
  const [error, setError] = useState("");

  const [passwordRules, setPasswordRules] = useState({
    minLength: false,
    specialChar: false,
    number: false,
    uppercase: false,
  });

  const checkCurrentPassword = () => {
    // Here you would check the current password (usually with an API call)
    // This is just a placeholder logic
    if (currentPassword === "1") {
      setPasswordChecked(true);
      setError("");
    } else {
      setError("Incorrect current password.");
    }
  };

  const changePassword = () => {
    if (
      newPassword === confirmNewPassword &&
      Object.values(passwordRules).every(Boolean)
    ) {
      // Here you would change the password (usually with an API call)
      setPasswordChanged(true);
      setError("");

      // Logic to redirect after 5 seconds
      setTimeout(() => {
        // Redirect logic here
      }, 5000);
    } else {
      setError("Passwords do not match.");
    }
  };

  const validatePassword = (password: string) => {
    const rules = {
      minLength: password.length >= 8,
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      number: /\d/.test(password),
      uppercase: /[A-Z]/.test(password),
    };
    setPasswordRules(rules);
  };

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    validatePassword(newPassword);
  };

  return (
    <Box sx={tw`flex ml-8`} width={800}>
      <Box sx={tw`flex flex-col w-1/2`}>
        <Headline>Change my password</Headline>

        {!isPasswordChanged && (
          <Box component="form" sx={{ mt: 4 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="current-password"
              label="Enter your current password"
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              autoComplete="off"
              disabled={isPasswordChecked}
            />
            <Button
              color="success"
              size="large"
              variant="contained"
              sx={{ width: 200, mt: 2 }}
              onClick={checkCurrentPassword}
              disabled={isPasswordChecked}
            >
              Check it!
            </Button>
            {error && !isPasswordChecked && (
              <Typography color="error">{error}</Typography>
            )}
          </Box>
        )}

        {
          //isPasswordChecked && !isPasswordChanged &&
        }
        {!isPasswordChanged && isPasswordChecked && (
          <Stack direction="row" width={800}>
            <Box component="form" sx={tw`mt-8`}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="new-password"
                label="Enter your new password"
                type="password"
                value={newPassword}
                onChange={handleNewPasswordChange}
                autoComplete="off"
                disabled={!isPasswordChecked || isPasswordChanged}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="confirm-new-password"
                label="Confirm your new password"
                type="password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                autoComplete="off"
                disabled={!isPasswordChecked || isPasswordChanged}
              />
              <Button
                color="success"
                size="large"
                variant="contained"
                sx={{ width: 200, mt: 2 }}
                onClick={changePassword}
                disabled={!isPasswordChecked || isPasswordChanged}
              >
                Change it
              </Button>
              {error && <Typography color="error">{error}</Typography>}
            </Box>

            <Box sx={tw`flex flex-col w-1/2 ml-8`}>
              <Typography variant="h6">Rules:</Typography>
              <PasswordRule isValid={passwordRules.specialChar}>
                spécial caractère
              </PasswordRule>
              <PasswordRule isValid={passwordRules.minLength}>
                8 letters
              </PasswordRule>

              <PasswordRule isValid={passwordRules.number}>
                one number
              </PasswordRule>
              <PasswordRule isValid={passwordRules.uppercase}>
                at least one capital letter
              </PasswordRule>
            </Box>
          </Stack>
        )}

        {isPasswordChanged && (
          <Box width={800}>
            <Typography sx={{ mt: 4 }}>
              Your password has been successfully changed!
            </Typography>

            <Typography sx={{ mt: 1 }} color={'red'}>
              You need to re-sign in, you will be redirected in 5 seconds.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ChangePassword;
