import { Stack, Link, Typography } from '@mui/material';
import tw from 'twin.macro';

const NavLinkSeparator = tw.span``;

const SignInSignup = () => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
      divider={<NavLinkSeparator>|</NavLinkSeparator>}
      paddingRight={2}
    >
      <Link href="taker-signin" sx={tw`font-semibold no-underline text-primary-500`}>
        Sign in / Sign up
      </Link>
    </Stack>
  );
};

const Account = () => {
  const name = 'Jean Doe';
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={2}
      divider={<NavLinkSeparator>|</NavLinkSeparator>}
      paddingRight={2}
    >
      <Typography component="h2" sx={tw`font-semibold text-green-500`}>
        Welcome {`${name}`}
      </Typography>
      <Link href="#" sx={tw`font-semibold no-underline text-primary-500`}>
        my account
      </Link>
      <Link href="#" sx={tw`font-semibold no-underline text-primary-500`}>
        signout
      </Link>
    </Stack>
  );
};

const TakerMenu = (props: { appearance?: 'light' | 'dark'; connected?: boolean }) => {
  const connected: boolean = props.connected ?? false;
  return <>{connected ? <Account /> : <SignInSignup />}</>;
};

export default TakerMenu;
