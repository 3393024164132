import { Container, Divider, Typography, Box, Link, Button } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';

const EndPoint = `
\`\`\`url
https://api.briicks.com
\`\`\`
`;

const APIKey = `
\`\`\`text
briicks_api_wXwzUMr3gg7GCF9VvjnkYQMDGSb8jxBnHZ34Ffc6tE3qYVpc8tryRCIGgTLajBzC
\`\`\`
`;

const MakerDeveloper = () => {
  return (
    <Container>
      <Typography variant="h4">Developer</Typography>
      <Divider />
      <Box>
        <Typography variant="body1" gutterBottom>
          Briicks's public API is built using GraphQL. If you're new to GraphQL,{` `}
          <Link href="https://blog.apollographql.com/the-basics-of-graphql-in-5-links-9e1dc4cac055" variant="body1">
            Apollo has resources for beginners.
          </Link>
          {` `}
          <Link href="https://graphql.org/" variant="body1">
            The official documentation
          </Link>
          {` `} is another good starting point.
        </Typography>
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h6">Endpoint</Typography>
        <MDEditor.Markdown source={EndPoint} />
      </Box>

      <Box sx={{ marginTop: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6">API Key</Typography>
          <Button color="error" variant="contained" size="small" style={{ marginLeft: 20, height: 20 }}>
            revoke
          </Button>
        </Box>

        <MDEditor.Markdown source={APIKey} />
      </Box>
    </Container>
  );
};

export default MakerDeveloper;
