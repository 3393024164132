import theme from 'themes/maker';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, Box } from '@mui/material';
import { MakerSideBar } from 'components/navigation/maker/sidebar';
import { Outlet } from 'react-router-dom';

const MakerLayout = () => {
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box sx={{ display: 'flex' }}>
            <MakerSideBar />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Outlet />
            </Box>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default MakerLayout;
