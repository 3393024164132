import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import {
  Settings,
  CategorizedStringMetadata,
  StringMetadata,
} from 'graphql/schema/__generated__/graphql-types';
import NumberFormat from 'react-number-format';
import tw from 'twin.macro';
import { extractFloatFromString } from 'utils';
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const MetricValue = (props: { category: string; element: StringMetadata; settings: Settings }) => {
  const { category, element, settings } = props;
  let unitMetrics: string | null = null;
  if (!['area'].includes(category.trim().toLowerCase())) {
    return <>{element.key.trim().toLowerCase() === 'no data' ? '' : element.value}</>;
  }

  if (category.trim().toLowerCase() === 'area') unitMetrics = ` ${settings.measurementMetrics}`;
  let value: number | string | null = extractFloatFromString(element.value);
  if (value === null) {
    return <>{element.value}</>;
  }

  if (unitMetrics === null) unitMetrics = '';

  return (
    <>
      <NumberFormat
        value={value}
        decimalScale={2}
        fixedDecimalScale
        displayType={'text'}
        thousandSeparator={' '}
        suffix={unitMetrics}
        renderText={(value: string) => <span>{value}</span>}
      />
    </>
  );
};

const SpecificationDetails = (props: {
  details: CategorizedStringMetadata;
  settings: Settings;
}) => {
  const { details, settings } = props;

  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>{details.category}</Title>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: 850, alignSelf: 'center', marginTop: 2 }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  '& .MuiTableCell-root': {
                    padding: 1,
                    //border: 1
                  },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {details?.values?.map((e, index: number) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {e.key}
                      </TableCell>
                      <TableCell align="right" sx={tw`capitalize`}>
                        <MetricValue category={details.category} settings={settings} element={e} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SpecificationDetails;
