import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketDataItemsFilters } from 'graphql/schema/__generated__/graphql-types';

export interface DynamicFilterState {
  filters: MarketDataItemsFilters;
  isLoading: boolean;
  error: string;
}

const initialState: DynamicFilterState = {
  filters: {
    countries: [],
    status: [],
    makers: [],
    progressStatus: []
  },
  isLoading: false,
  error: '',
};

export const DynamicFilterSlice = createSlice({
  name: 'DynamicFilterSlice',
  initialState,
  reducers: {
    getMarketDataFiltersRequest: (state, _action: PayloadAction) => {
      state.isLoading = true;
      state.error = '';
    },
    getMarketDataFiltersRequestSuccess: (state, action: PayloadAction<MarketDataItemsFilters>) => {
      state.isLoading = false;
      state.filters = action.payload;
    },
    getMarketDataFiltersRequestFaillure: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const DynamicFilterActions = DynamicFilterSlice.actions;
export default DynamicFilterSlice;
