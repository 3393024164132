import Logo from 'images/briiks-market.svg';
import { ReactComponent as FacebookIcon } from 'images/facebook-icon.svg';
import { ReactComponent as TwitterIcon } from 'images/twitter-icon.svg';
import { ReactComponent as YoutubeIcon } from 'images/youtube-icon.svg';
import { ReactComponent as TelegramIcon } from 'images/telegram-icon.svg';
import { ReactComponent as LinkedInIcon } from 'images/linkedin-icon.svg';
import { ReactComponent as InstagramIcon } from 'images/instagram-icon.svg';

const Config = {
  PoweredBy: {
    link: '#',
    name: 'Squiibz.com',
  },
  Logo: {
    imgSrc: Logo,
    text: 'Briicks Market',
  },
  Navigation: [
    {
      path: '/',
      name: 'home',
    },
    {
      path: '/market',
      name: 'market',
    },
    {
      path: '/briicks-maker',
      name: 'briicks maker',
    },
    {
      path: '/faq',
      name: 'faq',
    },
    {
      path: '/about-us',
      name: 'about us',
    },
  ],
  Social: [
    {
      icon: <FacebookIcon />,
      url: '#',
      name: 'Facebook',
    },
    {
      icon: <TwitterIcon />,
      url: '#',
      name: 'Twitter',
    },
    {
      icon: <TelegramIcon />,
      url: '#',
      name: 'Telegram',
    },
    {
      icon: <YoutubeIcon />,
      url: '#',
      name: 'Youtube',
    },
    {
      icon: <LinkedInIcon />,
      url: '#',
      name: 'LinkedIn',
    },
    {
      icon: <InstagramIcon />,
      url: '#',
      name: 'Instagram',
    },
  ],
};

export default Config;
