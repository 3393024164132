import { MarketData } from "graphql/schema/__generated__/graphql-types";
import { IFullAdsIndexMenu } from "./sections";
import styled from "styled-components";
import AdsShortCode from "../common/ads-short-code";
import tw from "twin.macro";
import AdStatus from "../common/ad-status";
import DaysCount from "../common/days-count";
import { Button, List, ListItem, Stack, Link } from "@mui/material";
import { SourcesIcon } from "components/misc/SourcesIcon";

const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
`;
const BadgesContainer = tw.div`
flex flex-col mb-2
`;

const Container = tw.div`
sticky top-0
`;

const NavigationContainer = tw.div`
p-2 bg-gray-200 rounded flex flex-col
`;

const MakerContainer = tw.div`
p-2 bg-gray-200 rounded flex flex-col mt-2
`;

const BriicksMakerLogo = tw.img`
w-auto h-8 mt-2
`;

const NavigationSideBar = (props: {
  listing: MarketData;
  visibleArticle: string;
  sections: IFullAdsIndexMenu[];
  isNavigation?: boolean;
}) => {
  const { listing } = props;
  return (
    <>
      <StickyContainer>
        <BadgesContainer>
          <AdsShortCode
            css={tw`bg-gray-200 ml-0 p-2 justify-center`}
            shortCode={listing.shortCode}
          />
          <AdStatus status={listing.progress?.label} />
          <DaysCount
            progress={listing.progress}
            firstLaunchSaleDate={listing.firstLaunchSaleDate}
            latestLaunchSaleDate={listing.latestLaunchSaleDate}
            finalSaleEndDate={listing.finalSaleEndDate}
          />
        </BadgesContainer>
        <Container>
          <NavigationContainer>
            {props.sections.map((e, index) => (
              <List
                key={index}
                sx={{
                  padding: 0,
                  "& .MuiTypography-root:hover": {
                    fontSize: 16,
                    "&, & .MuiListItemIcon-root": {
                      color: "#6415FF",
                    },
                  },
                }}
                subheader={
                  <h1 css={tw`text-primary-500 text-lg font-semibold`}>
                    {e.section}
                  </h1>
                }
              >
                {e.elements.map((o, index) => (
                  <ListItem
                    sx={{ paddingTop: 0, paddingBottom: 0 }}
                    key={index}
                  >
                    <Link
                      href={`#${o.name}`}
                      //color={o.name === props.visibleArticle ? '#6415FF' : '#243E63'}
                      color={"#243E63"}
                      variant="body2"
                      underline="none"
                    >
                      {o.name}
                    </Link>
                  </ListItem>
                ))}
              </List>
            ))}
          </NavigationContainer>
          <MakerContainer>
            <h1 css={tw`text-primary-500 text-base font-semibold`}>
              Briickmaker
            </h1>
            <BriicksMakerLogo
              src={SourcesIcon(listing.resources?.serviceProvider?.maker ?? "")}
            />
          </MakerContainer>
          <MakerContainer>
            <Stack>
              <Button
                sx={{ marginBottom: 1, marginTop: 1 }}
                variant="contained"
                color="success"
                fullWidth
                size="small"
                href={listing.settings?.originalAdsLink ?? "#"}
                target="_blank"
              >
                Invest Now
              </Button>
            </Stack>
          </MakerContainer>
        </Container>
      </StickyContainer>
    </>
  );
};

export default NavigationSideBar;
