import { Box, Link } from '@mui/material';
import parse from 'html-react-parser';
import styled from 'styled-components';
import tw from 'twin.macro';
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;
const AboutContent = styled.div`
  ${tw`mt-2`}
  h2 {
    ${tw`font-semibold py-2 mt-2 text-lg`}
  }
  a {
    ${tw`text-blue-500`}
  }
  ul {
    ${tw`list-disc list-inside`}
  }

  li {
    ${tw`mb-1 ml-4`}
  }
`;

function isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

const RestrictionItem = (props: { title: string; text: string }) => {
  const { text, title } = props;
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>{title}</Title>
        {isValidUrl(text) ? (
          <Link href={text} target="_blanck">
            Read more...
          </Link>
        ) : (
          <AboutContent>{parse(text, { trim: true })}</AboutContent>
        )}
      </Box>
    </>
  );
};

export default RestrictionItem;
