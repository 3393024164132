import { Legal } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import Identification from './identification';
import ExternalLinks from './external-links';
import Sponsor from './sponsor';
import Distribution from './distribution';

export const ARTICLE_TITLE = 'SPV';

const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;

const Divider = tw.hr`
`;

const SPV = (props: { legal: Legal; id?: any; viewPortObserver?: (id: string, isVisible: boolean) => void }) => {
  const { legal } = props;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
        <Headline>{ARTICLE_TITLE}</Headline>
        <Divider />
        <Identification spv={legal?.spv} />
        <ExternalLinks spv={legal?.spv} />
        <Sponsor spv={legal?.spv} />
        <Distribution spv={legal?.spv} />
      </ArticleContainer>
    </>
  );
};

export default SPV;
