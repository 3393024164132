import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Property } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const Classifications = (props: { property: Maybe<Property> }) => {
  const classification = props.property?.classification;
  const classificationItems: { key: string; value: string }[] = [
    { key: 'Use', value: classification?.use ?? 'no data' },
    { key: 'Categorie', value: classification?.category ?? 'no data' },
    { key: 'Type', value: classification?.type ?? 'no data' },
    { key: 'Year Built', value: classification?.builtYear ?? 'no data' },
  ];
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>Classification</Title>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: 850, alignSelf: 'center', marginTop: 2 }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  '& .MuiTableCell-root': {
                    padding: 1,
                    //border: 1
                  },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {classificationItems.map((e, index: number) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {e.key}
                      </TableCell>
                      <TableCell align="right" sx={tw`capitalize`}>
                        {e.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Classifications;
