import { Settings, Investment } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import TotalInvestment from './total-investment';
import CashFlow from './cash-flow';
import CashOnCash from './cash-on-cash';
import CapitalAppreciation from './capital-appresiation';
import ReturnOnInvest from './return-on-invest';
import Disclaimer from './disclaimer';

export const ARTICLE_TITLE = 'Metrics';

const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;
const Divider = tw.hr`
`;

const Metrics = (props: {
  investment: Investment;
  settings: Settings;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const { investment } = props;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
        <Headline>{ARTICLE_TITLE}</Headline>
        <Divider />
      </ArticleContainer>
      <TotalInvestment investment={investment} />
      <CashFlow investment={investment} />
      <CashOnCash investment={investment} />
      <CapitalAppreciation investment={investment} />
      <ReturnOnInvest investment={investment} />
      <Disclaimer />
    </>
  );
};

export default Metrics;
