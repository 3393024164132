import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MarketData, QueryMarketDataItemWithIdArgs } from "graphql/schema/__generated__/graphql-types";

export interface ListingDetailsState {
  marketDataItem: MarketData | null;
  isLoading: boolean;
  error: string;
}

const initialState: ListingDetailsState = {
  marketDataItem: null,
  isLoading: false,
  error: "",
};

export const MarketDataItemDetailsSlice = createSlice({
  name: "MarketDataItemDetailsSlice",
  initialState,
  reducers: {
    getMarketDataItemDetailsWitIdRequest: (
      state,
      _action: PayloadAction<QueryMarketDataItemWithIdArgs>
    ) => {
      state.isLoading = true;
      state.error = "";
    },
    getMarketDataItemDetailsWitIdRequestSuccess: (
      state,
      action: PayloadAction<MarketData>
    ) => {
      state.isLoading = false;
      state.marketDataItem = action.payload;
    },
    getMarketDataItemDetailsWitIdRequestFaillure: (
      state,
      action: PayloadAction<string>
    ) => {
      //state.listingsPage = initialState.listingsPage
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const MarketDataItemDetailsActions = MarketDataItemDetailsSlice.actions;
export default MarketDataItemDetailsSlice;
