import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ApiInputType } from "./types";
import {
  MarketDataSortBy,
  Market_Data_Items_Sort_By_Values,
} from "graphql/schema/__generated__/graphql-types";
import styled from "styled-components";
import tw from "twin.macro";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { useDebounce } from "helpers/use-debounce";
import ClearIcon from "@mui/icons-material/Clear";
import SortIcon from "@mui/icons-material/Sort";

const StickyGlobalFilters = styled.div`
  position: sticky;
  top: 0;
  z-index: 1000; // to make sure it stays on top of other elements
  ${tw`flex justify-between  p-4 w-full bg-white`}// add your own styling here
`;

enum SortBy {
  ADVERTISE_DATE,
  ROI,
  COC,
  SALE_VARIATION,
  BRIICK_PRICE,
  APPRECIATION,
}

enum SortDirection {
  ASC,
  DESC,
}

const GlobalFiltersSorts = forwardRef(
  (
    props: {
      apiInput: ApiInputType;
      onGlobalSearchChange: (query: string) => void;
      onSortChange: (sortBy: MarketDataSortBy) => void;
    },
    ref
  ) => {
    const { apiInput, onGlobalSearchChange, onSortChange } = props;
    const [globalSearch, setGlobalSearch] = useState("");
    const debouncedGlobalSearch = useDebounce(globalSearch, 500); // Debounce the search term by 500ms
    const [sortBy, setSortBy] = useState<{
      by: SortBy;
      direction: SortDirection;
    }>({
      by: SortBy.ADVERTISE_DATE,
      direction: SortDirection.DESC,
    });

    const onKeyDownGlobalSearch = (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        //setApiInput({ ...apiInput });
        onGlobalSearchChange(debouncedGlobalSearch.trim());
      }
    };
    const onChangeGlobalSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const search = e.currentTarget.value;
      setGlobalSearch(search);
    };

    const onClearGlobalSearch = () => {
      setGlobalSearch("");
    };

    const handleSortChange = (event: SelectChangeEvent<SortBy>) => {
      const value = event.target.value as SortBy;
      setSortBy({ ...sortBy, by: value });
    };

    const handleSortDirectionChange = () => {
      const direction =
        sortBy.direction === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC;
      setSortBy({ ...sortBy, direction });
    };

    const handleIncludeNoDataChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const checked = event.target.checked;
      if (!checked !== apiInput.sortBy.withNoData) {
        onSortChange({ by: apiInput.sortBy.by, withNoData: !checked });
      }
    };

    // Expose the child function to the parent component
    useImperativeHandle(ref, () => ({
      resetSort,
    }));

    const resetSort = () => {
      setSortBy({
        by: SortBy.ADVERTISE_DATE,
        direction: SortDirection.ASC,
      });
    };

    ////////effect

    useEffect(() => {
      const { by, direction } = sortBy;

      let sort: Market_Data_Items_Sort_By_Values | null = null;
      if (by === SortBy.ADVERTISE_DATE) {
        sort =
          direction === SortDirection.ASC
            ? Market_Data_Items_Sort_By_Values.DateAsc
            : Market_Data_Items_Sort_By_Values.DateDes;
      } else if (by === SortBy.BRIICK_PRICE) {
        sort =
          direction === SortDirection.ASC
            ? Market_Data_Items_Sort_By_Values.BriicksPriceAsc
            : Market_Data_Items_Sort_By_Values.BriicksPriceDes;
      } else if (by === SortBy.COC) {
        sort =
          direction === SortDirection.ASC
            ? Market_Data_Items_Sort_By_Values.CocAsc
            : Market_Data_Items_Sort_By_Values.CocDes;
      } else if (by === SortBy.ROI) {
        sort =
          direction === SortDirection.ASC
            ? Market_Data_Items_Sort_By_Values.RoiAsc
            : Market_Data_Items_Sort_By_Values.RoiDes;
      } else if (by === SortBy.SALE_VARIATION) {
        sort =
          direction === SortDirection.ASC
            ? Market_Data_Items_Sort_By_Values.SalesVariationAsc
            : Market_Data_Items_Sort_By_Values.SalesVariationDec;
      } else if (by === SortBy.APPRECIATION) {
        sort =
          direction === SortDirection.ASC
            ? Market_Data_Items_Sort_By_Values.AppreciationAsc
            : Market_Data_Items_Sort_By_Values.AppreciationDes;
      }

      if (sort && sort !== apiInput.sortBy.by) {
        onSortChange({ by: sort, withNoData: apiInput.sortBy.withNoData });
      }
    }, [sortBy]);

    useEffect(() => {
      onGlobalSearchChange(debouncedGlobalSearch.trim());
    }, [debouncedGlobalSearch]);

    useEffect(() => {
      if (apiInput.filters.globalSearch !== globalSearch) {
        setGlobalSearch(apiInput.filters.globalSearch || "");
      }
    }, [apiInput.filters.globalSearch]);

    return (
      <StickyGlobalFilters>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={globalSearch}
          placeholder="Search..."
          onKeyDown={onKeyDownGlobalSearch}
          onChange={onChangeGlobalSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {globalSearch && (
                  <IconButton edge="end" onClick={onClearGlobalSearch}>
                    <ClearIcon css={tw`text-blue-500`} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />

        <Box
          sx={{
            width: 335,
            display: "flex",
            flexDirection: "column",
            paddingLeft: 2,
          }}
        >
          <Stack direction="row" spacing={0.5}>
            <FormControl
              variant="outlined"
              size="small"
              style={{ marginTop: "0", width: 160 }}
            >
              <InputLabel id="sort-by-label">Sort by</InputLabel>
              <Select
                labelId="sort-by-label"
                label="Sort by"
                value={sortBy.by}
                onChange={handleSortChange}
              >
                <MenuItem value={SortBy.ADVERTISE_DATE}>
                  Advertise date
                </MenuItem>
                <MenuItem value={SortBy.COC}>C.O.C</MenuItem>
                <MenuItem value={SortBy.ROI}>R.O.I</MenuItem>
                <MenuItem value={SortBy.BRIICK_PRICE}>Briick price</MenuItem>
                <MenuItem value={SortBy.SALE_VARIATION}>
                  Sales variation
                </MenuItem>
                <MenuItem value={SortBy.APPRECIATION}>Appreciation</MenuItem>
              </Select>
            </FormControl>
            <Button
              onClick={handleSortDirectionChange}
              size="small"
              variant="text"
              startIcon={
                <SortIcon
                  sx={{
                    transform:
                      sortBy.direction === SortDirection.ASC
                        ? ""
                        : "rotate(180deg)",
                  }}
                />
              }
              sx={tw`text-sm`}
            >
              {sortBy.direction === SortDirection.ASC ? `A → Z` : `Z → A`}
            </Button>
          </Stack>

          {(sortBy.by === SortBy.ROI || sortBy.by === SortBy.APPRECIATION) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!apiInput.sortBy.withNoData || false}
                  onChange={handleIncludeNoDataChange}
                  inputProps={{ "aria-label": "Exclude no data" }}
                />
              }
              label="Exclude no data"
              style={{ marginTop: "0" }}
            />
          )}
        </Box>
      </StickyGlobalFilters>
    );
  }
);

export default GlobalFiltersSorts;
