import Header from 'components/header';
import Footer from 'components/footer';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import tw from 'twin.macro';
import { Box, Checkbox, FormControlLabel, TextField, Button, Link } from '@mui/material';

const Container = tw(ContainerBase)`-mx-8 px-4 `;
const Content = tw.div`max-w-screen-sm mx-auto py-20 lg:py-24`;
const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;
const Divider = tw.hr`
`;

const SignUp = () => {
  const handleSubmit = () => {};
  return (
    <>
      <Header />
      <Container>
        <Content>
          <Headline>Sign up</Headline>
          <Divider />
          <Box sx={tw`flex justify-center`}>
            <Box component="form" width={400} onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="first-name"
                label="First name"
                name="first-name"
                autoComplete="off"
                autoFocus
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="last-name"
                label="Last name"
                name="last-name"
                autoComplete="off"
                autoFocus
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="off"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="off"
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="confirm-password"
                label="Re-type password"
                type="password"
                id="confirm-password"
                autoComplete="off"
              />

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={
                  <span>
                    I agree to the <Link href="/terms-of-use">Terms of Use</Link> and{' '}
                    <Link href="/privacy-policy">Privacy Policy.</Link>
                  </span>
                }
                sx={{ display: 'block' }}
              />

              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 2 }}>
                <Button color="success" size="large" type="submit" variant="contained" sx={{ width: 200 }}>
                  Sign Up
                </Button>
              </Box>
            </Box>
          </Box>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default SignUp;
