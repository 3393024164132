import styled from 'styled-components';
import tw from 'twin.macro';
import { keyframes } from 'styled-components';

const skeletonLoading = keyframes`
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
`;

//min-w-96
const Card = tw.div`min-w-86 max-w-sm overflow-hidden h-auto border-gray-200 rounded-lg border`;
const ImageContainer = styled.div`
  ${tw`w-full h-64 lg:h-72 bg-white border-b relative`}
`;
const Badges = styled.div`
  ${tw`absolute mx-2 my-2 top-0 left-0 flex-row w-48 text-xs`}
`;

const AdStatus = styled.span`
  ${tw`w-full h-8 rounded-md bg-gray-100 uppercase block`}
  animation: ${skeletonLoading} 2s infinite ease-in-out;
`;
const DayCount = styled.span`
  ${tw`w-full h-8 rounded-md bg-gray-100 uppercase block mt-1`}
  animation: ${skeletonLoading} 2s infinite ease-in-out;
`;
const SalesProgress = styled.div`
  ${tw`h-8 w-8 absolute rounded-full  top-0 right-0 mx-2 my-2 bg-gray-100`}
  animation: ${skeletonLoading} 2s infinite ease-in-out;
`;
const ControlsContainer = tw.div`
absolute flex bottom-0 left-0 right-0 flex-row text-xs
items-end
`;
const AdId = styled.div`
  ${tw`overflow-hidden h-6 w-24 rounded-md ml-1 mb-1 p-1 px-2 bg-gray-100`}
  animation: ${skeletonLoading} 2s infinite ease-in-out;
`;
const Restrictions = styled.div`
  ${tw`overflow-hidden h-6 w-24 rounded-md ml-1 mb-1 p-1 px-2 bg-gray-100`}
  animation: ${skeletonLoading} 2s infinite ease-in-out;
`;

const Controls = styled.div`
  ${tw`flex items-center h-12 w-20 bottom-0 right-0 p-3 bg-white 
rounded-tl-3xl place-self-end bg-gray-100`}
  animation: ${skeletonLoading} 2s infinite ease-in-out;
`;

const DetailsContainer = tw.div`
w-full p-2
`;

const Content = styled.div`
  ${tw`h-6 w-full bg-gray-100 mt-2`}
  animation: ${skeletonLoading} 2s infinite ease-in-out;
`;
/* 
const Content = tw.div`h-6 w-full mt-2 bg-gray-100`;

const AnimatedContent = styled(Content)`
  animation: ${skeletonLoading} 1.5s infinite linear;
`; */

const AssetSkeleton = () => {
  return (
    <Card>
      <ImageContainer>
        <Badges>
          <AdStatus />
          <DayCount />
        </Badges>
        <SalesProgress />

        <ControlsContainer>
          <div css={tw`flex-1 flex flex-row`}>
            <AdId />

            <Restrictions />
          </div>
          <Controls />
        </ControlsContainer>
      </ImageContainer>
      <DetailsContainer>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((e) => (
          <Content key={e} />
        ))}
      </DetailsContainer>
    </Card>
  );
};

export default AssetSkeleton;
