export const PrivacyPolicyData = `
# Introduction

Our company ("we", "us", "our") respects the privacy of its users ("you") and has developed this Privacy Policy to demonstrate its commitment to protecting your privacy.

# Information Collection and Use

We collect several different types of information for various purposes to provide and improve our Service to you. 

## Personal Data

While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:

- Email address
- First name and last name
- Phone number
- Address, State, Province, ZIP/Postal code, City
- Cookies and Usage Data
`;
