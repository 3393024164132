import { PhotoGallery } from 'graphql/schema/__generated__/graphql-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import { TabPanel as TabPanelBase } from 'components/tabs/tab-panel';
import { Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import QuiltedImageList from './quilted-photos';

const Container = tw.div`flex pt-4 flex-col`;
const TabPanel = styled(TabPanelBase)`
  ${tw`mt-1`}
`;

const PhotoGalleryView = (props: { photosGalleries: PhotoGallery[] } & any) => {
  const { photosGalleries } = props;
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Container>
        <Tabs
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          sx={{
            minHeight: 38,
            height: 38,
          }}
          value={value}
          onChange={handleChange}
        >
          {photosGalleries.map((gallery: PhotoGallery, index: number) => (
            <Tab key={index} label={gallery.category} />
          ))}
        </Tabs>
        {photosGalleries.map((gallery: PhotoGallery, index: number) => (
          <TabPanel key={index} index={index} value={value}>
            <QuiltedImageList images={gallery.pictures} />
          </TabPanel>
        ))}
      </Container>
    </>
  );
};

export default PhotoGalleryView;
