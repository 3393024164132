import { Legal } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import Details from './details';

export const ARTICLE_TITLE = 'Ledger';

const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;

const Divider = tw.hr`
`;

const LedgerDLT = (props: {
  legal: Legal;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  const { legal } = props;
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
        <Headline>{ARTICLE_TITLE}</Headline>
        <Divider />
        {
          legal?.ledger ? <Details ledger={legal.ledger} /> : 'no data'
        }
        
      </ArticleContainer>
    </>
  );
};

export default LedgerDLT;
