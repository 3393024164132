import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { Financial } from "graphql/schema/__generated__/graphql-types";
import NumberFormat from "react-number-format";
import tw from "twin.macro";
import FilePresentIcon from "@mui/icons-material/FilePresent";

const ValuesViews = (props: { financial: Financial }) => {
  const propertyValue = props.financial?.propertyValue || undefined;
  const metadata = propertyValue?.metadata;
  const total = propertyValue?.totalValue;

  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ width: 850, alignSelf: "center", marginTop: 2 }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  "& .MuiTableCell-root": {
                    padding: 1,
                    //border: 1
                  },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {metadata === undefined ? (
                    <TableRow>
                      <TableCell component="th">no data</TableCell>
                    </TableRow>
                  ) : (
                    metadata.map((e, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {e.key}
                        </TableCell>
                        <TableCell align="right" sx={tw`capitalize`}>
                          <NumberFormat
                            value={e.value}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={"text"}
                            thousandSeparator={" "}
                            prefix={"$ "}
                            renderText={(value: string) => <span>{value}</span>}
                          />
                          <IconButton
                            disabled
                            aria-label=""
                            size="small"
                            sx={{ marginLeft: 1 }}
                          >
                            <FilePresentIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="body1"
                        sx={tw`text-blue-500 font-bold`}
                      >
                        Total
                      </Typography>
                    </TableCell>
                    <TableCell align="right" sx={tw`text-blue-500 font-bold`}>
                      <Typography
                        variant="body1"
                        sx={tw`text-blue-500 font-bold`}
                      >
                        {total === undefined ? (
                          "no data"
                        ) : (
                          <NumberFormat
                            value={total}
                            decimalScale={2}
                            fixedDecimalScale
                            displayType={"text"}
                            thousandSeparator={" "}
                            prefix={"$ "}
                            renderText={(value: string) => <span>{value}</span>}
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ValuesViews;
