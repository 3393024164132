import {
  Bm_Progress_Satus,
  MarketData,
} from "graphql/schema/__generated__/graphql-types";
import styled from "styled-components";
import tw from "twin.macro";
import AdStatus from "../common/ad-status";
import SalesProgress from "./sales-progress";
import { Stack } from "@mui/material";
import AdsShortCode from "../common/ads-short-code";
import TakerRestrictions from "./taker-restrictions";
import Slider from "react-slick";
import { useState } from "react";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import TotalPropertyValue from "./total-property-value";
import ImageSlider from "./images-slide";
import BriicksView from "./briicks";
import MetricsView from "./metrics";
import AddressView from "./address";
import PropertyView from "./property";
import Maker from "./maker";
import VisitBuy from "./visit-buy";
import InvestmentView from "./investment";
import ShareWishListView from "./share-wishlist";
import DaysCount from "../common/days-count";
import AdvertiseDate from "./advertise-date";

//min-w-96
const Card = tw.div`min-w-86 max-w-sm overflow-hidden h-auto border-gray-400 rounded-lg border`;
const ImageContainer = styled.div`
  ${tw`w-full h-64 lg:h-72 border-gray-400 border-b relative`}
`;
const Badges = tw.div`
absolute mx-2 my-2 top-0 left-0 flex-row w-48 text-xs
`;

const ControlsContainer = tw.div`
absolute flex bottom-0 left-0 right-0 flex-row text-xs
items-end
`;
const Controls = tw.div`
flex items-center justify-center bottom-0 right-0 p-3 bg-white 
rounded-tl-3xl place-self-end
`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`sm:mt-0 first:ml-0 ml-4 rounded-full p-0 text-lg`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const DetailsContainer = tw.div`
w-full p-2
`;

const Divider = tw.hr`
-mr-2 -ml-2 mt-1
`;

const Grid2ColsContainer = tw.div`
flex grid grid-cols-5 pt-1
`;

const BriicksShortAds = (props: { ads: MarketData }) => {
  const { ads } = props;
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);
  return (
    <Card>
      <ImageContainer>
        <ImageSlider
          onSliderRef={setSliderRef}
          photoGallery={ads.property?.mediaGallery?.photoGallery ?? []}
        />
        <Badges>
          <AdStatus status={ads.progress?.label ?? Bm_Progress_Satus.Bizzare} />
          <DaysCount
            progress={ads.progress}
            firstLaunchSaleDate={ads.firstLaunchSaleDate}
            latestLaunchSaleDate={ads.latestLaunchSaleDate}
            finalSaleEndDate={ads.finalSaleEndDate}
          />
        </Badges>
        <SalesProgress
          progress={ads.progress?.value ?? null}
          status={ads.progress?.label ?? Bm_Progress_Satus.Bizzare}
        />
        <ControlsContainer>
          <div css={tw`flex-1 flex flex-row`}>
            <Stack justifyContent="flex-end" sx={tw`w-full`}>
              <AdsShortCode shortCode={ads.shortCode ?? ""} />
              <AdvertiseDate date={ads.firstLaunchSaleDate} />
            </Stack>
            <Stack justifyContent="flex-end" sx={tw`w-full mr-1`}>
              {ads.legal?.restrictions.items && <TakerRestrictions />}
              <TotalPropertyValue
                value={ads.financial?.propertyValue?.totalValue ?? 0}
              />
            </Stack>
          </div>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}>
              <ChevronLeftIcon />
            </PrevButton>
            <NextButton onClick={sliderRef?.slickNext}>
              <ChevronRightIcon />
            </NextButton>
          </Controls>
        </ControlsContainer>
      </ImageContainer>
      <DetailsContainer>
        <BriicksView
          briicks={ads.investment?.offering.unit}
          stock={ads.sightStock}
        />
        <Divider />
        <MetricsView metrics={ads.investment?.metrics} />
        <Divider />
        <Grid2ColsContainer>
          <AddressView location={ads.property?.location} />
          <PropertyView
            property={ads.property}
            settings={ads.settings}
            maker={ads.resources?.serviceProvider?.maker}
          />
        </Grid2ColsContainer>
        <Divider />
        <Grid2ColsContainer>
          <InvestmentView investment={ads.investment} />
          <ShareWishListView />
        </Grid2ColsContainer>
        <Divider />
        <Grid2ColsContainer>
          <Maker maker={ads.resources?.serviceProvider?.maker} />
          <VisitBuy id={ads._id} />
        </Grid2ColsContainer>
      </DetailsContainer>
    </Card>
  );
};

export default BriicksShortAds;
