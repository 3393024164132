import Header from 'components/header';
import Footer from 'components/footer';
//import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import tw from 'twin.macro';
import styled from 'styled-components';
import { ReactElement, useState } from 'react';
import { motion } from 'framer-motion';
import ListBriicksMaker from './list';
import { BriiksMakers } from './makers';
import Map from './map';
import { SectionHeading, Subheading as SubheadingBase } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';

const HeaderRow = tw.div`flex justify-center items-center flex-col xl:flex-row`;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded rounded-full leading-none mt-0`;
const TabControl = styled.div<{ active: boolean }>`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded rounded-full transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${(props) => props.active && tw`bg-primary-500! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;

const TabContentContainer = tw.div`mt-8 w-full pointer-events-none`;

const CTAContainer = tw.div`w-full mt-10`;
const CTAHeader = tw(SectionHeading)`text-left`;
const CTASubheading = tw(SubheadingBase)`text-gray-700 text-2xl`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const PrimaryButton = styled(PrimaryButtonBase)<{ buttonRounded: boolean }>((props) => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`,
]);

interface ITabs {
  Map: ReactElement;
  List: ReactElement;
}

type TabsKey = keyof ITabs;
const BriicksMakerPage = () => {
  const tabs = {
    List: <ListBriicksMaker makers={BriiksMakers} />,
    Map: <Map />,
  };

  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);
  return (
    <>
      <>
        <Header />
        <Container>
          <ContentWithPaddingXl>
            <HeaderRow>
              <TabsControl>
                {Object.keys(tabs).map((tabName, index) => (
                  <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                    {tabName}
                  </TabControl>
                ))}
              </TabsControl>
            </HeaderRow>
            {tabsKeys.map((tabKey, index) => (
              <TabContent
                key={index}
                variants={{
                  current: {
                    opacity: 1,
                    scale: 1,
                    display: 'flex',
                  },
                  hidden: {
                    opacity: 0,
                    scale: 0.8,
                    display: 'none',
                  },
                }}
                transition={{ duration: 0.4 }}
                initial={activeTab === tabKey ? 'current' : 'hidden'}
                animate={activeTab === tabKey ? 'current' : 'hidden'}
              >
                <TabContentContainer>{tabs[tabKey as TabsKey]}</TabContentContainer>
              </TabContent>
            ))}

            <CTAContainer>
              <CTAHeader>Not yet listed?</CTAHeader>
              <CTASubheading>Increase your visibility, it's free!</CTASubheading>
              <Description css={tw`lg:w-2/3`}>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat."
              </Description>
              <PrimaryButton buttonRounded={true} as="a" href="/join-us">
                Get started
              </PrimaryButton>
            </CTAContainer>
          </ContentWithPaddingXl>
        </Container>

        <Footer />
      </>
    </>
  );
};

export default BriicksMakerPage;
