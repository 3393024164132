import { Settings, Financial } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
import ArticleContainer from 'components/article/article-container';
import Details from './details';

export const ARTICLE_TITLE = 'Liabilities';

const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;
const Divider = tw.hr`
`;

const Liabilities = (props: {
  financial: Financial;
  settings: Settings;
  id?: any;
  viewPortObserver?: (id: string, isVisible: boolean) => void;
}) => {
  return (
    <>
      <ArticleContainer id={props.id} viewPortObserver={props.viewPortObserver}>
        <Headline>{ARTICLE_TITLE}</Headline>
        <Divider />
        <Details />
      </ArticleContainer>
    </>
  );
};

export default Liabilities;
