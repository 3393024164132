import { Box, Typography } from '@mui/material';
import tw from 'twin.macro';

const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const Disclaimer = () => {
  const paragraphs: string[] = [];
  paragraphs.push(`This explanation provides a general overview of rental investment and
  should not be considered as financial advice. For personalized guidance on
  investing in rental properties, it is recommended to consult with a
  professional financial advisor who can help assess individual financial
  situations and goals.`);
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>Disclaimer</Title>
        <Box css={tw`bg-orange1-100 p-2 `}>
          {paragraphs.map((e, index: number) => (
            <Typography key={index} variant="body1" gutterBottom sx={{ marginTop: 1, fontStyle: 'italic' }}>
              {e}
            </Typography>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Disclaimer;
