import { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import tw from 'twin.macro';
import ConfirmOPT from './confirm-opt';

const Headline = tw.h1`
text-green-500 font-semibold text-2xl
`;

const ChangeEmail = () => {
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const validateEmail = (email: string) => {
    // Simple email validation
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (emailSubmitted) {
      console.log('mmmmmm');
    } else {
      if (validateEmail(email)) {
        setEmailSubmitted(true);
      } else {
        alert('Please enter a valid email.');
      }
    }
  };

  return (
    <>
      <Box sx={tw`flex flex-col ml-8`}>
        <Headline>Change email</Headline>
        <Box component="form" width={800} onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
          {!emailSubmitted ? (
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Enter your new email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="off"
              autoFocus
            />
          ) : (
            <p>Your email is: {email}</p>
          )}

          {emailSubmitted && <ConfirmOPT />}

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 2 }}>
            <Button color="success" size="large" variant="contained" sx={{ width: 200 }} type="submit">
              {emailSubmitted ? `validate` : `Change it`}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChangeEmail;
