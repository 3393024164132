import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { Investment } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
const Title = tw.h2`
text-blue-500 font-medium text-2xl mt-2
`;

const Contract = (props: { investment: Investment }) => {
  const  contract = props?.investment?.contract || undefined;

  const Data: { label: string; value: string }[] = [
    {
      label: 'Asset Type',
      value: contract?.assetType ?? 'no data',
    },
    {
      label: 'Asset Representation',
      value: contract?.assetRepresentation ?? 'no data',
    },
  ];
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Title>Contract</Title>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: 850, alignSelf: 'center', marginTop: 2 }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  '& .MuiTableCell-root': {
                    padding: 1,
                    //border: 1
                  },
                }}
                aria-label="simple table"
              >
                <TableBody>
                  {Data.map((e, index: number) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {e.label}
                      </TableCell>
                      <TableCell align="right" sx={tw`capitalize`}>
                        {e.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Contract;
