import Header from 'components/header';
import Footer from 'components/footer';
import { Container as ContainerBase } from 'components/misc/Layouts.js';
import tw from 'twin.macro';
import TakerAccount from 'pages/taker/account';

const Container = tw(ContainerBase)`-mx-8 px-4 `;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Headline = tw.h1`
text-primary-500 font-bold text-3xl
`;

const Divider = tw.hr`
`;

const TakerLayout = () => {
  return (
    <>
      <Header connected={true} />
      <Container>
        <Content>
          <Headline>My account</Headline>
          <Divider />
          <TakerAccount />
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default TakerLayout;
