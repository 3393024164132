import { elements as PropertyElements } from './property';
import { elements as InvestmentElements } from './investment';
import { elements as FinancialElements } from './financial';
import { elements as LegalElements } from './legal';
import { elements as ResourceElements } from './resources';

export interface IFullAdsSection {
  name: string;
  element: (ref?: any, viewPortObserver?: (isVisible: boolean) => void) => JSX.Element | null;
}

export interface IFullAdsIndexMenu {
  section: string;
  elements: IFullAdsSection[];
}

export const BriicksFullAdsSections: IFullAdsIndexMenu[] = [
  {
    section: 'Property',
    elements: PropertyElements,
  },
  {
    section: 'Investment',
    elements: InvestmentElements,
  },
  {
    section: 'Financial',
    elements: FinancialElements,
  },
  {
    section: 'Legal',
    elements: LegalElements,
  },
  {
    section: 'Resources',
    elements: ResourceElements,
  },
];
