import { Box } from '@mui/material';
const Details = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>No data</Box>
    </>
  );
};

export default Details;
