import styled from 'styled-components';
import Slider from 'react-slick';

export const SliderBase = styled(Slider)`
  .slick-slider {
    position: relative;
    display: flex;
    height: 100%;

    box-sizing: border-box !important;
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%; /* add this*/
  }

  .slick-slide {
    display: block;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;
