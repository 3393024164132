import { Maybe } from 'graphql/schema/__generated__/graphql-types';
import tw from 'twin.macro';
const Container = tw.div`flex justify-center items-center mt-8`;
const Head = tw.h1`
font-bold text-secondary-700 text-2xl
`;

const AdsTitle = (props: { title?: Maybe<string> }) => {
  return (
    <Container>
      <Head>{props.title ?? ""}</Head>
    </Container>
  );
};

export default AdsTitle;
